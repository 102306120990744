import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';
import { AnimationCollapse } from '../../animations';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MomentPipe } from '../../pipes/pipes';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css'],
  animations: [
    AnimationCollapse
  ],
  standalone: true,
  imports: [FormsModule, NgxBootstrapMultiselectModule, NgFor, NgClass, NgIf, TranslateModule, MomentPipe]
})
export class DashboardAdminComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private cookies: CookieService,
    private router: Router,
    private helper: HelperService,
    private cd: ChangeDetectorRef
  ) { };
  //Stores all notifications
  notifications: Notification[];
  //Stores device online/offline information
  onlineStatus: any;
  //Stores network failure data
  networkFailures: any;
  //Model used in notification form
  addNotification = new Notification;
  //Used to store the original notification in case user undo the editing
  originalNotification: Notification;
  //Used to show the undo button in the form
  insertNotificationFormEdit: boolean = false;
  //Used to store the notification index in case of undo
  notificationIndex: number = null;
  //Language selector options
  languages: any;
  //Selected language
  activeLanguage: string;
  //Stores all active old alerts in the system
  oldAlerts: any[];
  oldAlertState: boolean = false;
  oldAlertsLoaded: boolean;

  changeClientError: string = "";

  customerSelect = {
    settings: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-outline-dark fullWidth",
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true
    },
    texts: { defaultTitle: "" }
  }

  customerList: ICustomerOption[] = null;
  selectedCustomer: ICustomerOption[]

  searchModel: any = {
    keyword: "",
    results: null,
    type: "2",
    searchComplete: true,
    emptyResult: false
  };

  formSubmitSubject = new Subject();

  submitNotification() {
    //Init new array which is send to API. API accepts only array so single notification needs to be inside array
    let data: Notification[] = new Array();
    //Get the language from local storage
    let lang = this.activeLanguage;
    //Change the new notification language to the current language
    this.addNotification.language = lang;
    //Convert boolean to int
    this.addNotification.highlight = this.addNotification.highlight | 0
    //Push the single notification into the previously created array
    data.push(this.addNotification)
    //Check if notification has ID. If the ID is set then user is modifying existing notification and different function is used in the service
    let edit: boolean = this.addNotification.id ? true : false;
    //Call the service which makes request to API
    this.api.submitNotification(data, edit)
      .subscribe((response: any) => {
        //Clear form data
        this.addNotification = new Notification();
        this.insertNotificationFormEdit = false;
        this.originalNotification = null;
        //Load notifications from the server so newly added notification is displayed correctly
        this.api.getNotifications(lang, 7, false)
          .subscribe((response: any) => {
            this.notifications = response;
          })
      })
  }

  editNotification(notification: Notification): void {
    if (this.originalNotification) {
      //Insert the edited notification back to the array to the correct position
      this.notifications.splice(this.notificationIndex, 0, this.originalNotification);
    }
    //Get and set he index of notification which is being edited
    this.notificationIndex = this.notifications.indexOf(notification);
    if (this.notificationIndex !== -1) {
      //Remove notification from the list and UI
      this.notifications.splice(this.notificationIndex, 1);
    }
    //Set original notification into memory in case of undo. This needs to be done with JSON parse and stringify because by default JS just copies the reference of the variable
    this.originalNotification = JSON.parse(JSON.stringify(notification));
    //Set the notification into form
    this.addNotification = notification;
    //Display undo button
    this.insertNotificationFormEdit = true;
  }

  deleteNotification(notification: Notification): void {
    this.api.deleteNotification(notification.id)
      .subscribe((response: any) => {
        let index = this.notifications.indexOf(notification);
        if (index !== -1) {
          this.notifications.splice(index, 1);
        }
      })
  }

  undoFormEdit() {
    //Hide undo button
    this.insertNotificationFormEdit = false;
    if (this.notificationIndex !== -1) {
      //Insert the edited notification back to the array to the correct position
      this.notifications.splice(this.notificationIndex, 0, this.originalNotification);
    }
    //Clear form data
    this.addNotification = new Notification();
    this.originalNotification = null;
  }

  //Function which is called whenever user changes to language
  setActiveLanguage(language: any): void {
    //Set selected language as the active language
    this.activeLanguage = language.name
    //Load latest 5 notifications from the server
    this.api.getNotifications(this.activeLanguage, 7, false)
      .subscribe((response: Notification[]) => {
        this.notifications = response;
      })
  }

  changeClient(): void {
    const selectedCustomer = this.selectedCustomer[0];

    if (!selectedCustomer) {
      this.changeClientError = "ERROR";
      console.error("Error selecting a customer");
      return;
    }

    if (selectedCustomer?.isCompanyGroup) {
      this.changeCustomerGroup(selectedCustomer);
    }
    else {
      this.changeCustomer(selectedCustomer.id, selectedCustomer.name, selectedCustomer.server, null);
    }
  }

  changeCustomerGroup(option: ICustomerOption): void {
    this.changeClientError = "";

    const request = {
      companyGroupId: option.id,
      server: option.server
    }
    this.api.ssoAdminLogin(request).subscribe(res => {
      if (res) {
        // Set old admin data to another cookie and local storage for later use
        this.cookies.set("session-token-admin", this.cookies.get("session-token"), undefined, "/", undefined, false, "Lax");
        localStorage.setItem("user-admin", localStorage.getItem("user"));

        // Clear previous client data
        this.clearClientData();

        const landingPateData = {
          customers: res.customers,
          idProvider: res.user.samaccountname,
          user: res.user
        };

        this.cookies.set("session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");
        localStorage.setItem("landing-page", JSON.stringify(landingPateData));

        this.router.navigate(["landing-page"], {
          queryParams: null
        });
      }
    }, err => {
      console.error(err);
      this.changeClientError = err?.error?.status === 404
        ? "ADMIN_SSO_LOGIN_NOT_SUPPORTED_ERROR"
        : "ERROR";
    });
  }

  changeCustomer(id?: string, name?: string, server?: string, devId?: string): void {
    this.changeClientError = "";

    let data = {
      customerId: id || this.selectedCustomer[0]?.id,
      server: server || this.selectedCustomer[0]?.server,
      name: name || this.selectedCustomer[0]?.name
    }
    this.api.loginAsCustomer(data)
      .subscribe(res => {
        // Set old admin data to another cookie and local storage for later use
        this.cookies.set("session-token-admin", this.cookies.get("session-token"), undefined, "/", undefined, false, "Lax");
        localStorage.setItem("user-admin", localStorage.getItem("user"));

        // Set the token received from the server to the cookie.
        this.cookies.set("session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");

        // Add role numeric values
        switch (res.role) {
          case "User":
            res.roleLevel = 0
            break;
          case "Editor":
            res.roleLevel = 50
            break;
          case "MainUser":
            res.roleLevel = 100
            break;
          case "SystemAdmin":
            res.roleLevel = 200
            break;
          default:
            res.roleLeel = 0
            break;
        }

        // Clear previous client
        this.clearClientData();

        // Set logged in user data into local storage for later use.
        localStorage.setItem("user", JSON.stringify(res));
        localStorage.setItem("loggedCustomer", data.name + " (" + data.customerId + ") - " + data.server);

        if (devId) {
          this.router.navigate(['device/' + devId]);
        } else {
          this.router.navigate(['dashboard']);
        }
      }, err => {
        this.changeClientError = "ERROR";
        console.error(err);
      })
  }

  clearClientData(): void {
    localStorage.removeItem("features");
    localStorage.removeItem("customerInfo");
    localStorage.removeItem("user");
    localStorage.removeItem("loggedCustomer");
    localStorage.removeItem("landing-page");
  }

  getActivationName(group: number, node: number, type: string, customAct: string, connTimeLimit: number): string {
    return this.helper.getActivationName(group, node, type, customAct, connTimeLimit);
  }

  search(): void {
    this.searchModel.emptyResult = false;
    this.searchModel.results = null;
    this.searchModel.searchComplete = false;
    this.api.search(this.searchModel.type, encodeURIComponent(this.searchModel.keyword))
      .subscribe(res => {
        this.searchModel.searchComplete = true;
        //Check if result is empty
        this.checkResult(res);
        this.searchModel.results = res;
      }, err => {
        //In case of error set search as complete, empty array as result and empty result to true. Display empty search result error in UI
        this.searchModel.searchComplete = true;
        this.searchModel.results = [];
        this.searchModel.emptyResult = true;
      })
  }

  checkResult(data: any): void {
    //If all arrays in result are empty then display error for empty result
    if ((data.devices && data.devices.length === 0) &&
      (data.locations && data.locations.length === 0) &&
      (data.customers && data.customers.length === 0) &&
      (data.personnel && data.personnel.length === 0) &&
      (data.alarmRoutes && data.alarmRoutes.length === 0) &&
      (data.keys && data.keys.length === 0)) {
      this.searchModel.emptyResult = true;
    }
  }

  loadActiveAlerts(): void {
    this.oldAlertsLoaded = false;
    this.api.getActiveAlerts(true)
      .subscribe(res => {
        this.oldAlertsLoaded = true;
        if (res) {
          this.oldAlerts = res;
        } else {
          this.oldAlerts = null;
        }
      })
  }

  cancelAlert(alert: any): void {
    this.api.cancelActiveAlert(alert.taskId, alert.server)
      .subscribe(res => {
        alert.cancelled = true;
      },
        err => {
          alert.error = err;
        })
  }

  openOldAlerts(): void {
    this.oldAlertState = !this.oldAlertState;
    if (!this.oldAlertState) {
      this.oldAlerts = null;
      return;
    }
    //Check that activation translations are loaded into local storage before loading active alerts. Don't load translations again if they are in local storage
    if (!localStorage.getItem("act")) {
      this.api.getActivationTranslations(this.activeLanguage)
        .subscribe((response: any) => {
          localStorage.setItem("act", JSON.stringify(response));
          //Load active alerts when activation alerts are loaded
          this.loadActiveAlerts();
        });
    } else {
      //Load active alerts if translations were already in local storage
      this.loadActiveAlerts();
    }
  }

  checkAdminStatus(): void {
    let user: any = JSON.parse(localStorage.getItem("user"));
    if (user && user.roleLevel <= 100) {
      window.location.href = 'dashboard';
    }
    if (this.cookies.get("session-token-admin")) {
      this.cookies.set("session-token", this.cookies.get("session-token-admin"), undefined, "/", undefined, false, "Lax")
      this.cookies.delete("session-token-admin", "/", undefined, false, "Lax");
      let user = localStorage.getItem("user-admin");
      let lang = localStorage.getItem("language");
      localStorage.clear();
      localStorage.setItem("user", user);
      localStorage.setItem("language", lang);
      window.location.href = 'dashboard/admin';
    }
  }

  getDevType(type: string): string {
    return this.api.getDeviceType(type, false);
  }

  ngOnInit() {
    this.checkAdminStatus();
    this.languages = [
      {
        imgUrl: 'assets/img/flags/fi.png',
        name: 'fi'
      },
      {
        imgUrl: 'assets/img/flags/sv.png',
        name: 'sv'
      },
      {
        imgUrl: 'assets/img/flags/uk.png',
        name: 'en'
      },
      {
        imgUrl: 'assets/img/flags/ee.png',
        name: 'ee'
      }
    ];
    //Get language from the local storage
    let lang = localStorage.getItem("language");
    this.activeLanguage = lang;
    //Load latest 5 notifications from the server
    this.api.getNotifications(lang, 7, false)
      .subscribe((response: Notification[]) => {
        this.notifications = response;
      })
    //Load online status from all of the servers
    this.api.getOnlineStatus(true)
      .subscribe((response: any) => {
        this.onlineStatus = response;
      });
    //Load all available customers
    this.api.getAllCustomers()
      .subscribe((res: ICustomerOption[]) => {
        if (res) {
          const list = res.map(option => {
            const a: any = {};
            a.id = option;
            a.name = `${option.name} (${option.id}) - ${option.postOffice} - ${option.isCompanyGroup ? "Landing page - " : ""}${option.server}`;
            return a;
          })
          this.customerList = list;
          this.cd.detectChanges();
        }
      })

    //Load network failure data
    this.api.getNetworkFailures()
      .subscribe(res => {
        this.networkFailures = res;
      })

    this.translate.get(['CUSTOMER']).subscribe(t => {
      this.customerSelect.texts.defaultTitle = t.CUSTOMER
    })

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitNotification();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

interface ICustomerOption {
  id: string;
  name: string;
  postOffice: string;
  server: string;
  isCompanyGroup: boolean;
}

class Notification {
  id: number;
  language: string;
  subject: string;
  message: string;
  highlight: number = 0;
  timeStamp: string;
}
