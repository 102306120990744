import { saveAs } from 'file-saver';
import { AlarmService } from './alarm.service';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { IReportCSVRow } from 'app/models/report-csv.model';
import { CameraEvent, LockEvent } from 'app/models/report.model';
import { Angular2Csv } from 'angular2-csv';
import { TranslateService } from '@ngx-translate/core';
import { CareRecipientStatistics, IAlertStatistics, IEmaacStatistics, StatisticsFilters } from 'app/models/statistics.model';


@Injectable({
  providedIn: 'root'
})
export class FileExportService {

  constructor(
    private helper: HelperService,
    private translate: TranslateService,
    private alarmService: AlarmService,
  ) {
    this.alarmService.getAlarmReasonTranslations().subscribe((translations) => {
      this._alarmReasonTranslations = new Map(translations.alarmReason.map((x) => [x.key, x]));
      this._alarmCategoryTranslations = new Map(translations.alarmCategory.map((x) => [x.key, x]));
    });
  }

  private _alarmReasonTranslations: Map<string, { key: string; translation: string; categoryId: number; }>;
  private _alarmCategoryTranslations: Map<string, { key: string; translation: string; categoryId: number; }>;

  /**
   * Export alerts to CSV file
   * @param events Events to export
   * @param filename Name for the exported file
   * @param options Options for the CSV
   */
  exportAlertsToCSV(events: any[], filename: string, options: any): void {
    const csvData = this._parseAlertsToCsv(events);
    new Angular2Csv(csvData, filename, options);
  }

  /**
   * Export report alerts to JSON file
   * @param alerts Alerts to export
   * @param filename Filename for the exported file
   */
  exportAlertsToJson(alerts: any[], filename: string): void {
    const jsonData = this._parseAlertsToJson(alerts);
    this.exportJsonFile(jsonData, filename);
  }

  /**
   * Export camera events to CSV file
   * @param events Camera events to export
   * @param filename Name for the exported file
   * @param options Options for the CSV
   */
  exportCameraEventsToCSV(events: CameraEvent[], filename: string, options: any): void {
    const csvData = this._parseCameraEventsToCsv(events);
    new Angular2Csv(csvData, filename, options);
  }

  /**
   * Export lock events to CSV file
   * @param events Lock events to export
   * @param filename Name for the exported file
   * @param options Options for the CSV
   */
  exportLockEventsToCSV(events: LockEvent[], filename: string, options: any): void {
    const csvData = this._parseLockEventsToCsv(events);
    new Angular2Csv(csvData, filename, options);
  }

  /**
   * Export alert statistics to JSON file
   * @param statistics alert statistics to export
   * @param filters filters used for the statistics
   */
  exportAlertStatisticsJSON(statistics: IAlertStatistics, filters: StatisticsFilters): void {
    const jsonData = structuredClone(statistics);
    const startDate = this.helper.formatDate(filters.startDate.toString(), "DD.MM.YYYY");
    const endDate = this.helper.formatDate(filters.endDate.toString(), "DD.MM.YYYY");

    jsonData["alertsByDays"]?.forEach((obj) => obj["deviceType"] = this._getDeviceType(obj?.deviceType));
    jsonData["alertsByHours"]?.forEach((obj) => obj["deviceType"] = this._getDeviceType(obj?.deviceType));
    jsonData["deviceAlerts"]?.forEach((obj) => obj["deviceType"] = this._getDeviceType(obj?.deviceType));

    jsonData["alertsByTypes"]?.forEach((obj) => {
      for (const activation of obj.activations) {
        activation["alertType"] = this._getAlertType(obj?.deviceType, activation?.group, activation?.node);

        // Remove these keys from the object, since they don't contain any useful information
        delete activation["group"];
        delete activation["node"];
      }
      obj["deviceType"] = this._getDeviceType(obj.deviceType);
    });

    this.exportJSON(JSON.stringify(jsonData, null, 2), `statistics-alerts-${startDate}_${endDate}`);
  }

  /**
   * Export emaac statistics to JSON file
   * @param emaacStatistics emaac statistics to export
   * @param filters filters used for the statistics
   */
  exportEmaacStatisticsJSON(emaacStatistics: IEmaacStatistics, filters: StatisticsFilters): void {
    const jsonData = structuredClone(emaacStatistics);
    const startDate = this.helper.formatDate(filters.startDate.toString(), "DD.MM.YYYY");
    const endDate = this.helper.formatDate(filters.endDate.toString(), "DD.MM.YYYY");

    jsonData["alertsByDays"]?.forEach((obj) => obj["deviceType"] = this._getDeviceType(obj?.deviceType));
    jsonData["alertsByHours"]?.forEach((obj) => obj["deviceType"] = this._getDeviceType(obj?.deviceType));
    jsonData["responseTimes"].alertResponses?.forEach((obj) => obj["deviceType"] = this._getDeviceType(obj?.deviceType));

    jsonData["alertsByAlarmReasons"]?.forEach((obj) => {
      obj["alarmReasonCategory"] = this._translateAlarmCategory(obj?.alarmReasonCategory);

      for (const alert of obj["alarmReasonAlerts"]) {
        alert["alarmReason"] = this._translateAlarmReason(alert?.alarmReason);
      }
    })

    this.exportJSON(JSON.stringify(jsonData, null, 2), `statistics-emaac-${startDate}_${endDate}`);
  }

  /**
   * Export care recipient statistics to JSON file
   * @param careRecipientStats care recipient statistics to export
   * @param filters filters used for the statistics
   */
  exportCareRecipientStatisticsJSON(careRecipientStats: CareRecipientStatistics[][], filters: StatisticsFilters): void {
    const copy = structuredClone(careRecipientStats).flat();
    const startDate = this.helper.formatDate(filters.startDate.toString(), "DD.MM.YYYY");
    const endDate = this.helper.formatDate(filters.endDate.toString(), "DD.MM.YYYY");
    const jsonData: any[] = [];

    for (const stat of copy) {
      let obj = {};

      stat?.data?.alertCountByAlarmReasons?.forEach((reason) => {
        // Translate alarm reason and remove the key from the object for the JSON readability
        reason["alarmReason"] = this._translateAlarmReason(reason.key);
        delete reason["key"];
      });

      obj["totalAlerts"] = stat?.data?.totalAlerts;
      obj["avgWaitTimeSeconds"] = stat?.data?.avgWaitTimeSeconds;
      obj["avgCareTimeSeconds"] = stat?.data?.avgCareTimeSeconds;
      obj["totalAlarmReasons"] = stat?.data?.totalAlarmReasons;
      obj["alertCountByAlarmReasons"] = stat?.data?.alertCountByAlarmReasons;
      obj["recipientName"] = stat?.card?.name;
      obj["devices"] = stat?.card?.devices;
      obj["customerId"] = stat?.card?.customerId;
      obj["notes"] = stat?.card?.notes;

      jsonData.push(obj);
    }

    this.exportJSON(JSON.stringify(jsonData, null, 2), `statistics-care-recipients-${startDate}_${endDate}`);
  }

  /**
   * Export JSON data to file
   * @param jsonData Data for the JSON file export
   * @param filename Name for the exported file
   */
  exportJsonFile(jsonData: any, filename: string): void {
    const blob = new Blob([JSON.stringify(jsonData)], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename + ".json");
  }

  exportJSON(json: string, fileName: string): void {
    const blob = new Blob([json], { type: 'application/json' });
    saveAs(blob, fileName + '.json');
  }

  /**
   * Parse report alerts to JSON format
   * @param alerts Alerts to parse
   * @returns Parsed alerts for the JSON
   */
  private _parseAlertsToJson(alerts: any[]): any[] {
    const forJson = structuredClone(alerts);
    const eventTypeIds = ["ema", "call", "sms", "email", "comment"];

    forJson.forEach((row) => {
      let eventTypeId = "ema";

      for (eventTypeId of eventTypeIds) {
        if (typeof row[eventTypeId + "Events"] !== "undefined") {

          for (let i = 0; i < row[eventTypeId + "Events"].length; i++) {

            if (typeof row[eventTypeId + "Events"][i].eventType !== "undefined") {
              row[eventTypeId + "Events"][i].eventType =
                this.translate.instant(row[eventTypeId + "Events"][i].eventType);

              row[eventTypeId + "Events"][i].time =
                this.helper.formatDate(row[eventTypeId + "Events"][i].time, "l LTS");
            }

            if (eventTypeId == "comment") {
              row[eventTypeId + "Events"][i].CommentTime =
                this.helper.formatDate(row[eventTypeId + "Events"][i].CommentTime, "l LTS");
            }
          }
        }
      }

      if (row.alarmReasons.length > 0) {
        for (const alarmReasons of row.alarmReasons) {
          alarmReasons.alarmReason = this._translateAlarmReason(
            alarmReasons.alarmReason
          );

          alarmReasons.alarmReasonCategory = this._translateAlarmCategory(
            alarmReasons.alarmReasonCategory
          );
        }
      }

      const activationStr = this.helper.getActivationName(
        row.activationGroup,
        row.activationNode,
        row.deviceType,
        null,
        0
      );

      if (typeof activationStr === "string") row["activationName"] = activationStr;

      row["deviceTypeName"] = this.helper.getDeviceType(row.deviceType);
      row["completeTime"] = this.helper.formatDate(row.compeleteTime, "l LTS");
      row["createTime"] = this.helper.formatDate(row.createTime, "l LTS");
      row["receiveTime"] = this.helper.formatDate(row.receiveTime, "l LTS");
    });

    return forJson;
  }

  /**
   * Parse alerts to CSV format
   * @param alerts Events to parse
   * @returns Parsed events for the CSV
   */
  private _parseAlertsToCsv(alerts: any[]): IReportCSVRow[] {
    const csvData = [];
    const forCsv = structuredClone(alerts);

    forCsv.forEach((row) => {
      const csvRow: IReportCSVRow = {
        CreateTime: this.helper.formatDate(row.receiveTime, "l LTS"),
        Device: row.deviceName,
        EventType: this.helper.getActivationName(
          row.activationGroup,
          row.activationNode,
          row.deviceType,
          row.customActivationName,
          row.connTimeLimit
        ),
        Recipient: row.resolverName,
        TransferTime: this.helper.formatDate(row.completeTime, "l LTS"),
      };

      csvData.push(csvRow);
    });

    return csvData
  }

  /**
   * Parse camera events to CSV format
   * @param events Camera events to parse
   * @returns Parsed events for the CSV
   */
  private _parseCameraEventsToCsv(events: CameraEvent[]): IReportCSVRow[] {
    const csvData = [];
    const forCsv = structuredClone(events);

    forCsv.forEach((row) => {
      const csvRow: IReportCSVRow = {
        CreateTime: this.helper.formatDate(row.visitStarted, "l LTS"),
        Device: row.camera.displayName,
        EventType: row.camera.visitType,
        Recipient: row.userName || "Visit not performed",
        TransferTime: this.helper.formatDate(row.visitEnd, "l LTS"),
      }

      csvData.push(csvRow);
    });

    return csvData;
  }

  /**
   * Parse lock events to CSV format
   * @param events Lock events to parse
   * @returns Parsed events for the CSV
   */
  private _parseLockEventsToCsv(events: LockEvent[]): IReportCSVRow[] {
    const csvData = [];
    const forCsv = structuredClone(events);

    forCsv.forEach((row) => {
      const csvRow: IReportCSVRow = {
        CreateTime: this.helper.formatDate(String(row.createdTime), "l LTS"),
        Device: row.lockName,
        EventType: row.eventType,
        Recipient: row.userName,
      }

      csvData.push(csvRow);
    });

    return csvData;
  }

  /**
   * Translate alarm reason
   * @param reasonKey Alarm reason dictionary key
   * @returns Alarm reason translation
   */
  private _translateAlarmReason(reasonKey: string) {
    const obj = this._alarmReasonTranslations.get(reasonKey);
    return obj ? obj.translation : "";
  }

  /**
   * Translate alarm category
   * @param categoryKey Alarm category dictionary key
   * @returns Alarm category translation
   */
  private _translateAlarmCategory(categoryKey: string) {
    const obj = this._alarmCategoryTranslations.get(categoryKey);
    return obj ? obj.translation : "";
  }


  private _getDeviceType(deviceType: string): string {
    return this.helper.getDeviceType(deviceType);
  }

  private _getAlertType(deviceType: string, group: number, node: number): string {
    return this.helper.getActivationName(group, node, deviceType, "", 0);
  }
}
