import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/services/api.service';
import { ReportPDFRequest, TimeZone } from 'app/models/reportrequestdata.model';
import * as moment from "moment-timezone";
import { saveAs } from "file-saver";
import { FileExportService } from 'app/services/file-export.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CameraEvent, LockEvent } from 'app/models/report.model';
import { ReportService } from 'app/services/report.service';
import { ReportFilter } from 'app/services/helper.service';


@Component({
  selector: 'app-download-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatExpansionModule,
    TranslateModule,
  ],
  templateUrl: './download-panel.component.html',
  styleUrls: ['./download-panel.component.css'],
})
export class DownloadPanelComponent implements OnDestroy{
  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private reportService: ReportService,
    private fileExporter: FileExportService,
  ) {}

  PDFRequestPanelExpanded: boolean = false;

  loadingCSV: boolean = false;
  loadingPDF: boolean = false;
  loadingJSON: boolean = false;

  pdfError: boolean = false;

  failedPDFdownloadAttempts = 0;

  @Input() PDFReportRequestSuccess: boolean;
  @Output() PDFReportRequestSuccessEvent = new EventEmitter<boolean>();

  @Input() PDFReportRequestFailed: boolean;
  @Output() PDFReportRequestFailedEvent = new EventEmitter<boolean>();

  @Input() limitExceed: boolean;
  @Output() limitExceedEvent = new EventEmitter<boolean>();

  @Input() alertLimitValue: string;
  @Output() alertLimitValueEvent = new EventEmitter<string>();

  @Input() filterStartDay: any;
  @Input() filterEndDay: any;
  @Input() filterStartTime: any;
  @Input() filterEndTime: any;
  @Input() advancedFilter: ReportFilter;
  @Input() weekDayFilter: any;
  @Input() customDeviceOption: any;
  @Input() customDeviceQuery: any;
  @Input() customBasestationOption: any;
  @Input() customBasestationQuery: any;
  @Input() cameraVisitsEnabled: boolean;
  @Input() selectedTab: any;

  reportReqId = null;
  reportFileUrl = "";

  private destroy$ = new Subject<void>();

  @Input() user;

  triggerPdfGeneration(): void {
    this.failedPDFdownloadAttempts = 0;
    if (this.pdfError) {
      localStorage.removeItem("reportfile");
      this.loadingPDF = false;
      this.pdfError = false;
      return;
    }
    let fromTimeStamp: any;
    let toTimeStamp: any;
    let fromTimeOfDay: string;
    let toTimeOfDay: string;
    let locationFilter: string[];
    let alertRouteFilter: string[];
    let deviceFilter: string[];
    let basestationFilter: string[];
    let personnelFilter: string[];
    let deviceTypeFilter: string[];
    let activationFilter: string[];
    let weekDayFilter: number[];

    fromTimeStamp = moment(this.filterStartDay).startOf("d").utc();
    toTimeStamp = moment(this.filterEndDay).endOf("d").utc();

    if (this.filterStartTime !== "00:00" || this.filterEndTime !== "23:59") {
      fromTimeOfDay = moment(this.filterStartTime, "HH:mm")
        .utc()
        .format("HH:mm")
        .toString();
      toTimeOfDay = moment(this.filterEndTime, "HH:mm")
        .utc()
        .format("HH:mm")
        .toString();
    }

    if (this.advancedFilter.location.selectedList) {
      locationFilter = this.advancedFilter.location.selectedList;
    }
    if (this.advancedFilter.location.selectedList) {
      locationFilter = this.advancedFilter.location.selectedList;
    }
    if (this.advancedFilter.alertRoute.selectedList) {
      alertRouteFilter = this.advancedFilter.alertRoute.selectedList;
    }
    if (this.advancedFilter.deviceTypes.selectedList) {
      deviceTypeFilter = this.advancedFilter.deviceTypes.selectedList;
    }
    if (this.advancedFilter.device.selectedList) {
      deviceFilter = this.advancedFilter.device.selectedList.slice();
      deviceFilter = deviceFilter.filter(
        (deviceId) => deviceId !== this.customDeviceOption.id
      );
      if (this.customDeviceQuery) {
        deviceFilter.push(this.customDeviceQuery);
      }
    }
    if (this.advancedFilter.basestation.selectedList) {
      basestationFilter = this.advancedFilter.basestation.selectedList.slice();
      basestationFilter = basestationFilter.filter(
        (baseStationId) => baseStationId !== this.customBasestationOption.id
      );
      if (this.customBasestationQuery) {
        basestationFilter.push(this.customBasestationQuery);
      }
    }
    if (this.weekDayFilter) {
      weekDayFilter = this.weekDayFilter;
    }
    if (this.advancedFilter.personnel.selectedList) {
      personnelFilter = this.advancedFilter.personnel.selectedList;
    }
    if (this.advancedFilter.activations.selectedList) {
      activationFilter = this.advancedFilter.activations.selectedList;
    }

    const reportPDFRequest: ReportPDFRequest = {
      reportType: this.selectedTab,
      emailList: [this.user.email],
      language: this.user.language,
      fromTimestamp: fromTimeStamp,
      toTimestamp: toTimeStamp,
      fromTimeOfDay: fromTimeOfDay,
      toTimeOfDay: toTimeOfDay,
      locationFilter: locationFilter,
      alertRouteFilter: alertRouteFilter,
      deviceFilter: deviceFilter,
      basestationFilter: basestationFilter,
      personnelFilter: personnelFilter,
      deviceTypeFilter: deviceTypeFilter,
      activationFilter: activationFilter,
      weekDayFilter: weekDayFilter,
      locationTimeZone: this.getCustomerTimeZone()
    };

    // console.log("COMP reportPDFRequest", reportPDFRequest)

    this.api
      .triggerPDFGeneration(reportPDFRequest)
      .then((PDFReportFile) => {
        localStorage.setItem("reportfile", PDFReportFile.result);
        this.PDFReportRequestSuccess = true;
        this.PDFReportRequestSuccessEvent.emit(this.PDFReportRequestSuccess); // Emit the event
        this.user.email = "";
        setTimeout(() => {
          this.PDFReportRequestSuccess = false;
          this.PDFReportRequestSuccessEvent.emit(this.PDFReportRequestSuccess); // Emit the event
        }, 7000);
        this.togglePDFChecker();
      })
      .catch((_) => {
        this.PDFReportRequestFailed = true;
        this.PDFReportRequestFailedEvent.emit(this.PDFReportRequestFailed); // Emit the event
        this.user.email = "";
        setTimeout(() => {
          this.PDFReportRequestFailed = false;
          this.PDFReportRequestFailedEvent.emit(this.PDFReportRequestFailed); // Emit the event
        }, 7000);
      });

    this.loadingPDF = true;
  }

  checkPDFReady() {
    var reportFile = localStorage.getItem("reportfile");
    if (!this.failedPDFdownloadAttempts) this.loadingPDF = true;
    // console.log("Checking PDF ready", this.failedPDFdownloadAttempts)

    this.api
      .downloadReport(reportFile)
      .then((blob) => {
        if (blob != null) {
          clearInterval(this.reportReqId);
          this.reportReqId = null;
          localStorage.removeItem("reportfile");
          this.reportFileUrl =
            window.location.origin + "/reports/download?file=" + reportFile;
          this.loadingPDF = false;
          saveAs(blob, `report-${reportFile}`);
        }
      })
      .catch((c) => {
        // console.log("Error downloading report", c.error, c.message)
        if (c.error) {
          var msg = c.message.split('_');

          if (msg[0] == "ALERT" && msg[1] == "LIMIT") {

            this.limitExceed = true;
            this.limitExceedEvent.emit(this.limitExceed);

            this.alertLimitValue = msg[2];
            this.alertLimitValueEvent.emit(this.alertLimitValue);

            this.PDFReportRequestFailed = true;
          }

          this.failedPDFdownloadAttempts++;

        } else if (typeof c.error === 'undefined') {
          this.failedPDFdownloadAttempts++;
        }

        if (this.failedPDFdownloadAttempts == 10) {
          this.pdfError = true;
          localStorage.removeItem("reportfile");
          this.clearCheckInterval();
        }

      })
      .then(() => { });
  }

  isPanelExpandedAndFileExists() {
    return this.PDFRequestPanelExpanded && localStorage.getItem("reportfile") != null;
  }

  setupCheckInterval() {
    this.checkPDFReady();
    if (this.reportReqId == null)
      this.reportReqId = setInterval(() => this.checkPDFReady(), 5000);
  }

  clearCheckInterval() {
    clearInterval(this.reportReqId);
    this.reportReqId = null;
  }

  togglePDFChecker() {
    if (this.isPanelExpandedAndFileExists()) {
      this.setupCheckInterval();
    } else {
      this.clearCheckInterval();
    }
  }

  downloadCsvOrJson(exportType: "csv" | "json"): void {
    switch (this.selectedTab) {
      case "Alert":
        this.exportAlertsToCsvOrJson(exportType);
        break;
      case "CameraVisit":
        this.exportCameraEventsToCsv();
        break;
      case "LockEvent":
        this.exportLockEventsToCsv();
        break;
    }
  }

  exportAlertsToCsvOrJson(exportType: "csv" | "json"): void {
    const filename = "alerts-" + moment().format("YYYY-MM-DD");
    const headers = ["Start time", "Device", "Alert type", "User", "End time"];
    const options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      headers: headers,
    };

    if (exportType == "csv") this.loadingCSV = true;
    if (exportType == "json") {
      this.loadingJSON = true;
    };

    this.fetchAlertsForDownload().then((alerts) => {
      if (alerts) {
        const exportData = alerts ?? [];
        switch (exportType) {
          case "csv":
            this.fileExporter.exportAlertsToCSV(exportData, filename, options);
            this.loadingCSV = false;
            break;
          case "json":
            this.fileExporter.exportAlertsToJson(exportData, filename);
            this.loadingJSON = false;
            break;
        }
        this.PDFRequestPanelExpanded = false;
      }
    });
  }

  fetchAlertsForDownload(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let alertsData: any = {
        paging: {
          alertsPerPage: 0,
          pageNumber: 1,
        },
        fieldFilter: [
          "taskId",
          "createTime",
          "receiveTime",
          "deviceName",
          "deviceId",
          "activationGroup",
          "activationNode",
          "deviceType",
          "resolverName",
          "deliveryType",
          "customActivationName",
          "completeTime",
          "environmentName",
          "environmentId",
          "emaEvents",
          "callEvents",
          "commentEvents",
          "confirmed",
          "alarmReasons",
        ],
      };
      alertsData = this.reportService.setReportFilters(
        alertsData,
        this.advancedFilter,
        this.customDeviceOption,
        this.customDeviceQuery,
        this.customBasestationOption,
        this.customBasestationQuery,
        this.weekDayFilter,
        this.filterStartDay,
        this.filterEndDay,
        this.filterStartTime,
        this.filterEndTime);  // this.setReportFilters(alertsData);
      this.api.getAlertHistory(alertsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  exportCameraEventsToCsv(): void {
    const filename = "camera-events-" + moment().format("YYYY-MM-DD");
    const headers = ["Start time", "Device", "Type of visit", "User", "End time"];
    const options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      headers: headers
    };

    this.loadingCSV = true;

    this.fetchCameraVisitsForDownload().then((events) => {
      if (events) {
        const exportData = events ?? []
        this.fileExporter.exportCameraEventsToCSV(exportData, filename, options);

        this.loadingCSV = false;
        this.PDFRequestPanelExpanded = false;
      }
    });
  }

  fetchCameraVisitsForDownload(): Promise<CameraEvent[]> {
    return new Promise((resolve, reject) => {
      const page = 1
      const pageSize = 0;
      this.reportService.getCameraVisit(
        page,
        pageSize,
        this.advancedFilter,
        this.customDeviceOption,
        this.customDeviceQuery,
        this.customBasestationOption,
        this.customBasestationQuery,
        this.weekDayFilter,
        this.filterStartDay,
        this.filterEndDay,
        this.filterStartTime,
        this.filterEndTime,
        this.cameraVisitsEnabled)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    })
  }

  exportLockEventsToCsv(): void {
    const filename = "lock-events-" + moment().format("YYYY-MM-DD");
    const options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
    };

    this.loadingCSV = true;

    this.fetchLockingEventsForDownload().then((events) => {
      if (events) {
        this.fileExporter.exportLockEventsToCSV(events, filename, options);

        this.loadingCSV = false;
        this.PDFRequestPanelExpanded = false;
      }
    });
  }

  fetchLockingEventsForDownload(): Promise<LockEvent[]> {
    return new Promise((resolve, reject) => {
      const page = 1
      const pageSize = 0;
      this.reportService.getLockingEvents(
        page,
        pageSize,
        this.advancedFilter,
        this.customDeviceOption,
        this.customDeviceQuery,
        this.customBasestationOption,
        this.customBasestationQuery,
        this.weekDayFilter,
        this.filterStartDay,
        this.filterEndDay,
        this.filterStartTime,
        this.filterEndTime)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    })
  }

  getCustomerTimeZone(): number | null {
    let customerInfo = localStorage.getItem("customerInfo");
    let customerTimeZone: string = customerInfo ? JSON.parse(customerInfo).timezone : null;
    if (customerTimeZone in TimeZone) {
      return TimeZone[customerTimeZone as keyof typeof TimeZone];
    } else {
      // Handle invalid timezone value
      return null;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
