import { AlarmRoute } from "./alarmroute.model";
import { SortOrder } from "app/services/helper.service";

export class Device {
  activityMonitoring: boolean;
  addedToSystem?: Date;
  additionalAlarmInfo?: string;
  additionalInfo?: string;
  address?: string;
  alarmRoutes?: AlarmRoute[];
  allSubClasses?: any[];
  audioLockOpenDtmfString: string;
  basestationId: string;
  batteryState: number;
  batteryStateList: DeviceBatteryLevel[];
  bitParaSettings: BitParaSettings;
  bluetoothSettings?: any;
  collapseState?: boolean;
  connectionTimeLimit?: number;
  connectionType: ConnectionType;
  delayACPowerAlert: number;
  devClass?: number;
  devPrefix?: string;
  devSubClass?: number;
  devSubType?: string;
  deviceActivations: DeviceActivation[];
  deviceType: string;
  ethernetSettings: any;
  externalSystemId: string;
  externalSystemPassword: string;
  gsmNumber: string;
  id: string;
  everWatchId?: string;
  ignoreAutoAnswerNumbers: string[];
  iamOk: any;
  keyEnabled?: boolean;
  lastConnection?: Date;
  locationId: string;
  locationName: string;
  lockInformation?: LockDeviceInformation;
  lowBatteryLevel?: number;
  lowBatteryWarning: number;
  maxCallDuration: number;
  maxCallDurationExtended: number;
  modemSettings: any;
  movementTimeLimit?: number;
  name: string;
  networkSimSettings?: any;
  onlineStatus: string;
  pairedDevices?: Device[];
  postCode?: string;
  postOffice?: string;
  residentDisplayName: string;
  residentId: number;
  replaceDevice: string;
  signalLevels: DeviceSignalLevel[];
  softwareUpdateOngoing: boolean;
  softwareVersion?: number;
  swUdEndTime: string;
  swUdStartTime: string;
  tasksFromBase: number;
  tasksToBase: number;
  terminalParameters?: DeviceTerminalParameters;
  toBeDeleted: boolean;
  transportOrder: any;
  vpnSettings: any;
  wifiHotSpotSettings: any;
  wifiSettings: any;
}

export class LockDeviceInformation {
  lockSerialNumber?: string;
  lockedDoorTypeID?: number;
  floorNumber?: number;
  roomNumber?: number;
  residentId?: number;
  firstName?: string;
  familyName?: string;
}

export class BitParaSettings {
  led?: boolean;
  alarmBeep: boolean;
  presenceMsg?: boolean;
  clock?: boolean;
  autoAnswer?: boolean;
  ledPulseAlert?: boolean;
  qmsgEnabled?: boolean;
  ringerVolume?: number;
  battLedDisabled?: boolean;
  homeCare?: boolean;
  clockBrightness?: number;
  clockAls?: boolean;
  roamingDisabled?: boolean;
  displayQmsgHost?: boolean;
}

export class DeviceActivation {
  activationGroup: number;
  activationNode: number;
  customActivationName: string;
  alarmRoute: AlarmRoute;
}

export class DeviceSignalLevel {
  signalLevel: number;
  operator: string;
  mccMnc: string;
  logTime: Date;
  networkMode: string;
  networkSurvey: boolean;
}

export class DeviceBatteryLevel {
  batteryLevel: number;
  batteryType: number;
  lastChangeTime: Date;
}

export class DeviceTerminalParameters {
  deviceSubClass: number;
  fallingDetection: boolean;
  movementMonitoring: boolean;
  accessControl: boolean;
  lockEscort: boolean;
  longPress: boolean;
  alwaysRoaming: boolean;
}

export class DeviceResidentCompany {
  residentId: number;
  fName: string;
  lName: string;
  mName: string;
  displayName: string;
  ssNumber: string;
  company?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  doorCode?: string;
  phoneCell?: string;
  drivingDirections?: string;
  importantInformation?: string;
}

export interface ResidentSelect {
  id: number;
  name: string;
}

export class DeviceCompanyGroup implements IDeviceInventory {
  companyId: string;
  company: string;
  companyAddress: string;
  locationId: string;
  location: string;
  locationAddress: string;
  devId: string;
  devClass: number;
  devType: string;
  devTypeName: string;
  deviceName: string;
  leasingTime: string;
  residentId: number;
  resident: string;
  sSnumber: string;
  gsmNumber?: string;
  swrel: number;
  lastConnection: string;
  statusOnline: boolean;
  statusOfflineUnder24h: boolean;
  statusOfflineOver24h: boolean;
  statusLowBattery: boolean;
  statusInEveronStock: boolean;
  statusKey: string;
  statusInService: boolean;
  statusLost: boolean;

  address: string;
  onlineStatus: string;
  lowbattery: boolean;
  bindingTimeStart: Date;
  bindingTimeEnd: Date;
  inUse: boolean; // true = has leasing time, false = no leasing time
}

export interface IDeviceInventory {
  companyId: string;
  company: string;
  companyAddress: string;
  locationId: string;
  location: string;
  locationAddress: string;
  devId: string;
  devClass: number;
  devType: string;
  devTypeName: string;
  deviceName: string;
  gsmNumber?: string;
  leasingTime: string;
  residentId: number;
  resident: string;
  sSnumber: string;
  swrel: number;
  deviceInfo?: string;
  additionalInfo?: string;
  lastConnection: string;
  statusOnline: boolean;
  statusOfflineUnder24h: boolean;
  statusOfflineOver24h: boolean;
  statusLowBattery: boolean;

  statusInEveronStock: boolean;
  statusInService: boolean;
  statusLost: boolean;
  statusKey: string;
  bindingTimeStart: Date;
  bindingTimeEnd: Date;
}

export interface IDeviceInventoryFilters {
  pageSize: number;
  pageIndex: number;
  search: string;
  language: string;
  sortKey: DeviceSorter;
  sortBy: SortOrder;
  deviceTypes: string[];
  locations: string[];
  statusOnline: boolean;
  statusOfflineUnder24h: boolean;
  statusOfflineOver24h: boolean;
  statusLowBattery: boolean;
  statusInCustomerStock: boolean;
  statusInEveronStock: boolean
  statusInService: boolean;
  statusLost: boolean;
}

export interface IDeviceTypeSummary {
  deviceType: string;
  totalAmount: number;
  inUseAmount: number;
  inStockAmount: number;
  inServiceAmount: number;
}

export class DeviceCSV {
  id: string;
  name: string;
  company: string;
  location: string;
  address: string;
  postalCode: string;
  city: string;
  gsm: string;
  productType: string;
  softwareVersion: number;
  dateAdded: string;
  additionalInfo: string;
  deviceInfo: string;
  status: string;
}

export type DeviceStatus = "SERVICE" | "LOST" | "CUSTOMER_INVENTORY";

export class ReplaceDeviceForm {
  constructor(defectiveDeviceId: string, replaceId: string) {
    this.defectiveDeviceId = defectiveDeviceId;
    this.replaceDeviceId = replaceId;
  }

  public readonly defectiveDeviceId: string; // The original ID of the device to be replaced
  public readonly replaceDeviceId: string;
  public deviceStatus: DeviceStatus;
}

export enum DeviceReplacementStatus {
  Success = 0,
  Failed,
  ReplaceDeviceNotValid,
  ReplaceDeviceAlreadyInUse,
  GenericError,
  DeviceNotReplaceableWithGivenType,
  DeviceReplacementNotSupported,
  ReplacedDeviceNotOffline,
  ReplacedDeviceSoftwareTooOld,
  ReplacedDeviceNotFound,
}

export enum DeviceSorter {
  DeviceType,
  DeviceName,
  Location,
  Address,
  Resident,
  SSnumber,
  BindingTime,
  DeviceId,
  Status,
}

export enum DeviceSortKey {
  lowbattery = 1,
  offline = 2,
  offlineUnder24h = 3,
  online = 4,
  lost = 5,
  service = 6,
  noStatus = 7,
  inventory = 8,
  everonInventory = 9
}

/**
 * Modem/GSM = 0,
 * Ethernet/LAN = 1,
 * Wifi = 2
 */
export enum ConnectionType {
  GSM,
  LAN,
  Wifi
}
