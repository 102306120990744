<div class="viewContainer">
    <h4 class="blueHeader">{{ "STATISTICS" | translate }}</h4>
    <div class="row mt-3">
        <div class="col">
            <!--Tab selects-->
            <span class="cursorPointer" [ngClass]="selectedTab === 'alerts' ? 'darkUnderline' : 'defaultUnderline'" (click)="selectedTab = 'alerts'">
                <span class="tabLabel" [ngClass]="{ highlightedLabel: selectedTab === 'alerts'}">
                    {{ "ALERTS_TAB" | translate}}
                </span>
            </span>

            <ng-container *ngIf="EMAACEnabled">
                <span class="defaultUnderline cursorDefault">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <span class="cursorPointer" [ngClass]=" selectedTab === 'alarmCenter' ? 'darkUnderline' : 'defaultUnderline'" (click)="selectedTab = 'alarmCenter'">
                    <span class="tabLabel" [ngClass]="{ highlightedLabel: selectedTab === 'alarmCenter' }">
                        {{ "ALARM_CENTER" | translate }}
                    </span>
                </span>
            </ng-container>

            <span class="defaultUnderline cursorDefault">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <span class="cursorPointer" [ngClass]="selectedTab === 'careRecipients' ? 'darkUnderline' : 'defaultUnderline'" (click)="selectedTab = 'careRecipients'">
                <span class="tabLabel" [ngClass]="{ highlightedLabel: selectedTab === 'careRecipients'}">
                    {{ "CARE_RECIPIENTS" | translate }}
                </span>
            </span>
        </div>
        <div class="col">
            <!-- TODO
            <div class="d-flex flex-row-reverse">
                <button class="btn btn-outline-primary">
                    <span class="fa-sharp fa-solid fa-download"></span>
                    {{ "DOWNLOAD" | translate }}
                </button>
                <div class="input-group col-7">
                    <select class="form-control savedViewsSelect">
                        <option selected>
                            {{ "SAVED_VIEWS" | translate }}
                        </option>
                    </select>
                    <div>
                        <button class="newViewButton" type="button">
                            +&nbsp;{{ "NEW_VIEW" | translate }}
                        </button>
                    </div>
                </div>
            </div>
            -->
        </div>
    </div>

    <div class="blueCard row ml-0 mt-3">
        <div class="col-10">
            <div class="row" style="min-height: 57px;">
                <p id="timeLabel">{{ "TIME_TEXT" | translate }}:</p>

                <div id="timeFilterSelectContainer">
                    <button #dropdownBtn class="selectDropdownBtn mb-3" (click)="showTimeOptions = true">
                        <i class="fa-regular fa-calendar-range fa-lg"></i>
                        <p>{{ selectedDateOptionText }}</p>
                        <i class="fa-regular fa-chevron-down"></i>
                    </button>

                    <div *ngIf="showTimeOptions" class="customDropdown" #dropdownMenu>
                        <div class="dropdownOption" (click)="onDateSelect(dateOptions.today)">
                            {{ dateOptions.today.text }}
                        </div>

                        <div class="dropdownOption" (click)="onDateSelect(dateOptions.yesterday)">
                            {{ dateOptions.yesterday.text }}
                        </div>

                        <div class="dropdownOption" (click)="onDateSelect(dateOptions.lastWeek)">
                            {{ dateOptions.lastWeek.text }}
                        </div>

                        <div class="dropdownOption" (click)="onDateSelect(dateOptions.lastMonth)">
                            {{ dateOptions.lastMonth.text }}
                        </div>

                        <div class="dropdownOption" (click)="onDateSelect(dateOptions.last90Days)">
                            {{ dateOptions.last90Days.text }}
                        </div>

                        <div class="dropdownOption" (click)="onDateSelect(dateOptions.custom)">
                            {{ dateOptions.custom.text }}...
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="selectedDateOption === 'custom'">
                    <div class="mb-3">
                      <div class="datePickerContainer">
                          <small class="dateLabel">{{ "REPORT_START_DATE" | translate }}</small>

                          <div>
                            <mat-form-field appearance="outline" class="customDateSelect mr-3">
                                <input
                                  matInput
                                  [max]="currentDate"
                                  [matDatepicker]="filterStartDatePicker"
                                  [(ngModel)]="statistics.filters.startDate"
                                />
                                <mat-datepicker-toggle matSuffix class="customDatePicker" [for]="filterStartDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #filterStartDatePicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="datePickerContainer">
                          <small class="dateLabel">{{ "REPORT_END_DATE" | translate }}</small>

                          <div>
                            <mat-form-field appearance="outline" class="customDateSelect">
                                <input
                                  matInput
                                  [min]="dateLimit"
                                  [max]="currentDate"
                                  [matDatepicker]="filterEndDayPicker"
                                  [ngModel]="statistics.filters.endDate"
                                  (dateChange)="onCustomEndDateSelect($event)"
                                />
                                <mat-datepicker-toggle matSuffix class="customDatePicker" [for]="filterEndDayPicker"></mat-datepicker-toggle>
                                <mat-datepicker #filterEndDayPicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                      </div>
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <p id="filtersLabel">{{ "FILTERS" | translate }}:</p>

                <div class="form-group mr-3" *ngIf="filterSelects.location.filterList" [hidden]="['alarmCenter'].includes(selectedTab)">
                    <ngx-bootstrap-multiselect
                    [options]="filterSelects.location.filterList"
                    [settings]="filterSelects.settings"
                    [texts]="filterSelects.location.texts"
                    [(ngModel)]="statistics.filters.locations"
                    name="locationsFilter"
                    ></ngx-bootstrap-multiselect>
                </div>

                <div class="form-group mr-3" *ngIf="filterSelects.device.filterList">
                    <ngx-bootstrap-multiselect
                    [options]="filterSelects.device.filterList"
                    [settings]="filterSelects.settings"
                    [texts]="filterSelects.device.texts"
                    [(ngModel)]="statistics.filters.devices"
                    name="devicesFilter"
                    ></ngx-bootstrap-multiselect>
                </div>

                <div class="form-group mr-3" *ngIf="filterSelects.deviceTypes.filterList">
                    <ngx-bootstrap-multiselect
                    (ngModelChange)="onDeviceTypeFilterChange($event)"
                    [options]="filterSelects.deviceTypes.filterList"
                    [settings]="filterSelects.singleSelectSettings"
                    [texts]="filterSelects.deviceTypes.texts"
                    [(ngModel)]="statistics.filters.deviceTypes"
                    name="deviceTypesFilter"
                    ></ngx-bootstrap-multiselect>
                </div>

                <div class="form-group mr-3" *ngIf="filterSelects.activations.filterList && statistics.filters.deviceTypes.length > 0">
                    <ngx-bootstrap-multiselect
                    [options]="filterSelects.activations.filterList"
                    [settings]="filterSelects.settings"
                    [texts]="filterSelects.activations.texts"
                    [(ngModel)]="statistics.filters.alertTypes"
                    name="alertTypesFilter"
                    ></ngx-bootstrap-multiselect>
                </div>

                <div class="form-group mr-3" *ngIf="filterSelects.alertRoute.filterList">
                    <ngx-bootstrap-multiselect
                    [options]="filterSelects.alertRoute.filterList"
                    [settings]="filterSelects.settings"
                    [texts]="filterSelects.alertRoute.texts"
                    [(ngModel)]="statistics.filters.alertRoutes"
                    name="alertRoutesFilter"
                    ></ngx-bootstrap-multiselect>
                </div>

                <div class="form-group mr-3" *ngIf="filterSelects.personnel.filterList" [hidden]="['alarmCenter'].includes(selectedTab)">
                    <ngx-bootstrap-multiselect
                    [options]="filterSelects.personnel.filterList"
                    [settings]="filterSelects.settings"
                    [texts]="filterSelects.personnel.texts"
                    [(ngModel)]="statistics.filters.personnels"
                    name="personnelsFilter"
                    ></ngx-bootstrap-multiselect>
                </div>

                <div class="mb-3">
                    <button class="btn btn-primary statisticsBtn mr-3" (click)="statistics.getStatisticsTabData(selectedTab)">
                        {{ "SEARCH" | translate }}
                    </button>

                    <button id="resetBtn" class="btn btn-outline-primary statisticsBtn" (click)="resetFilters()">
                        {{ "RESET" | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col">
            <button class="btn btn-primary float-right" (click)="exportJSON()">
                {{ ("EXPORT_TO" | translate) + " JSON" }}
            </button>
        </div>
    </div>

    <div class="mt-4 pb-4">
        <alerts-tab *ngIf="selectedTab === 'alerts'"></alerts-tab>
        <alarm-center-tab *ngIf="selectedTab === 'alarmCenter'"></alarm-center-tab>
        <care-recipients-tab *ngIf="selectedTab === 'careRecipients'"></care-recipients-tab>
    </div>
</div>
