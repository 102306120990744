import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { SlicePipe, CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Device, DeviceActivation, DeviceSignalLevel, DeviceResidentCompany, ResidentSelect } from '../../../models/device.model';
import { HelperService } from '../../../services/helper.service'
import { AlarmRouteModel } from '../../../models/alarmroutes.model';
import { UnlocDoorTypes } from 'app/models/unloc.model';
import { ViewChild } from '@angular/core';
import { MomentPipe, SignalQualityClassPipe } from '../../../pipes/pipes';
import { ThrottleButtonClickDirective } from '../../../directives/throttle-button-click.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, Subscription, of } from 'rxjs';
import { DisplayDeviceInfo } from './device-display-component/display-device-info.component';
import { DeviceSignalLevelsComponent } from './device-signal-levels/device-signal-levels.component';
import { LocationModel } from 'app/models/location.model';
import { catchError, concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.css'],
  animations: [
    AnimationCollapse
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapMultiselectModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    ThrottleButtonClickDirective,
    TranslateModule,
    DisplayDeviceInfo,
    DeviceSignalLevelsComponent
  ]
})
export class DeviceInfoComponent implements OnChanges {

  //Init global variables and services

  constructor(
    private api: ApiService,
    private helper: HelperService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {
  };

  @Input('device') device: Device;
  @Input('locations') locations: LocationModel[];
  @Input('residents') residents: DeviceResidentCompany[];
  @Output() updateDev = new EventEmitter<Device>();
  @Output() updateError = new EventEmitter<boolean>();
  @ViewChild('selectedResident') selectedResident: any;

  editMode: boolean = false;
  user: any;
  devEditForm: UntypedFormGroup;
  activationEditForm: UntypedFormGroup;
  signalLevels: DeviceSignalLevel[] = [];
  networkSurveyResults: any[] = [];
  alarmRouteLocations: AlarmRouteModel[];
  availableAlarmRouteLocations: AlarmRouteModel[];
  activationsMap: Map<string, DeviceActivation[]> = new Map();
  activationNames: string[];
  activationsSaveError: string;
  activationsSaveSuccess: string;
  activationsParsed: boolean;
  lockedDoorTypes = UnlocDoorTypes;
  currentDeviceResidentId: number;
  deviceResidentDisplayName: string;
  selected: string;

  residentSelect = {
    settings: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-outline-dark fullWidth",
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true
    },
    texts: {
      defaultTitle: ""
    },
    residentList: null,
    selectedResident: null
  }

  get activationsFormArray() {
    return this.activationEditForm.get('activations') as UntypedFormArray;
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  createForms(): void {
    this.devEditForm = this.fb.group({
      name: this.device.name,
      lockSerialNumber: this.device.lockInformation?.lockSerialNumber,
      locationId: [this.device.locationId, Validators.required],
      deviceResidentId: this.device.residentId,
      additionalInfo: this.device.additionalInfo,
      additionalAlarmInfo: this.device.additionalAlarmInfo,
      lockedDoorTypeID: this.device.lockInformation?.lockedDoorTypeID.toString(),
      residentId: this.device.lockInformation?.residentId,
      firstName: this.device.lockInformation?.firstName,
      familyName: this.device.lockInformation?.familyName,
      roomNumber: [this.device.lockInformation?.roomNumber, Validators.pattern("^[0-9]*$")],
      floorNumber: [this.device.lockInformation?.floorNumber, Validators.pattern("^[0-9]*$")],
      address: this.device.address,
      postCode: this.device.postCode,
      postOffice: this.device.postOffice,
      externalIdentifier: this.device.externalSystemId,
      externalPassword: this.device.externalSystemPassword,
      devSubClass: this.device.devSubClass
    });


    // Create empty formArray where we store activation formControlGroups
    this.activationEditForm = this.fb.group({
      activations: new UntypedFormArray([])
    });

    // Create formControlGroup for every activation
    this.activationsMap.forEach((activation, name) => {
      let activeAlarmRouteIds = activation.map(activation => activation.alarmRoute.id);

      // If routeIds is empty then it's disabled -> Convert into empty array.
      activeAlarmRouteIds = !activeAlarmRouteIds[0] ? [] : activeAlarmRouteIds;
      this.activationsFormArray.push(this.fb.group({
        // We only need details from the first activation, no reason to store them all because they are already grouped.
        activationDetails: {
          activationGroup: activation[0].activationGroup,
          activationNode: activation[0].activationNode,
          activationName: name
        },
        selectedRouteIds: [activeAlarmRouteIds],
      }));


      // Disable form if user.level less than 50.
      if (this.user?.roleLevel < 50) {
        this.activationEditForm.disable()
      }

    });

  }


  clearActivationRoutes(activationFormControlIndex: number): void {
    this.activationsFormArray.controls[activationFormControlIndex].get('selectedRouteIds').reset([]);
    this.activationsFormArray.controls[activationFormControlIndex].markAsDirty();
  }

  resetForm(): void {
    this.devEditForm.reset({
      name: this.device.name,
      lockSerialNumber: this.device.lockInformation?.lockSerialNumber,
      locationId: this.device.locationId,
      deviceResidentId: this.device.residentId,
      additionalInfo: this.device.additionalInfo,
      additionalAlarmInfo: this.device.additionalAlarmInfo,
      lockedDoorTypeID: this.device.lockInformation?.lockedDoorTypeID,
      residentId: this.device.lockInformation?.residentId,
      firstName: this.device.lockInformation?.firstName,
      familyName: this.device.lockInformation?.familyName,
      floorNumber: this.device.lockInformation?.floorNumber,
      roomNumber: this.device.lockInformation?.roomNumber,
      address: this.device.address,
      postCode: this.device.postCode,
      postOffice: this.device.postOffice,
      externalIdentifier: this.device.externalSystemId,
      externalPassword: this.device.externalSystemPassword,
      devSubClass: this.device.devSubClass
    });
  }

  featureEnabled(feature: string): boolean {
    let features: [String] = JSON.parse(localStorage.getItem('features'));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  submitActivationEdit(activationFormControlIndex): void {
    const activationDetails = this.activationsFormArray.controls[activationFormControlIndex].get('activationDetails').value;
    const routeIds = this.activationsFormArray.controls[activationFormControlIndex].get('selectedRouteIds').value;

    this.api.updateDeviceAlarmRoutes({
      'deviceId': this.device.id,
      'devClass': this.device.devClass,
      'alarmRoutes': routeIds,
      'group': activationDetails.activationGroup,
      'node': activationDetails.activationNode
    }).subscribe(res => {
      this.activationsFormArray.controls[activationFormControlIndex].markAsPristine();
      this.activationsSaveSuccess = activationDetails.activationName;
      this.activationsSaveError = null;

    }, err => {
      if (err.status === 400) {
        this.activationsSaveError = activationDetails.activationName;
        this.activationsSaveSuccess = null;
      }
    })

  }

  createDeviceToBeSaved(formData: any): Device {

    //Create new object from the original device
    const deviceToBeSaved = JSON.parse(JSON.stringify(this.device))

    let deviceResidentId = this.checkDeviceResidentId(formData.deviceResidentId);


    //Change the name and locationId of the new object to same as in form
    deviceToBeSaved.name = formData.name;
    deviceToBeSaved.locationId = formData.locationId;

    // Check if deviceResidentId has changed
    if (deviceToBeSaved.residentId !== deviceResidentId[0]) {
      this.devEditForm.patchValue({
        additionalAlarmInfo: ''
      }); // Reset additionalAlarmInfo
    }

    deviceToBeSaved.residentId = deviceResidentId[0];
    deviceToBeSaved.additionalInfo = formData.additionalInfo;
    deviceToBeSaved.additionalAlarmInfo = formData.additionalAlarmInfo;
    deviceToBeSaved.address = formData.address;
    deviceToBeSaved.postCode = formData.postCode;
    deviceToBeSaved.postOffice = formData.postOffice;
    deviceToBeSaved.externalSystemId = formData.externalIdentifier;
    deviceToBeSaved.externalSystemPassword = formData.externalPassword;

    //Change dev sub class and type if it was changed
    if (this.devEditForm.controls.devSubClass.dirty) {
      deviceToBeSaved.devSubClass = formData.devSubClass;
      let subClass = this.device.allSubClasses.find(x => x.devSubClass === deviceToBeSaved.devSubClass);
      deviceToBeSaved.devSubType = "SUBTYPE_" + subClass.subClassDescription;
    }

    //Add lock information to data if device is lock
    if (this.isLock()) {
      deviceToBeSaved.lockInformation.lockedDoorTypeID = formData.lockedDoorTypeID;
      deviceToBeSaved.lockInformation.residentId = formData.residentId;
      deviceToBeSaved.lockInformation.firstName = formData.firstName;
      deviceToBeSaved.lockInformation.familyName = formData.familyName;
      deviceToBeSaved.lockInformation.floorNumber = formData.floorNumber;
      deviceToBeSaved.lockInformation.roomNumber = formData.roomNumber;
      deviceToBeSaved.lockInformation.lockSerialNumber = formData.lockSerialNumber;
    }

    //Delete fields from JSON which will be sent to API
    delete deviceToBeSaved.deviceActivations;
    delete deviceToBeSaved.signalLevels;
    delete deviceToBeSaved.bitParaSettings;

    return deviceToBeSaved
  }

  checkDeviceResidentId(formFieldValue: any): any {

    let deviceResidentId = formFieldValue;

    if (!deviceResidentId) {
      deviceResidentId = [];
    }

    if (Array.isArray(deviceResidentId)) {
      if (deviceResidentId.length === 0) {
        deviceResidentId.push(0);
      }
    }

    return deviceResidentId;
  }

  submitDeviceEdit(): void {
    const deviceToBeSaved = this.createDeviceToBeSaved(this.devEditForm.value);

    //Check that modified data is different than original
    if (JSON.stringify(this.device) !== JSON.stringify(deviceToBeSaved)) {
      //Send save request to server
      this.saveDevice(deviceToBeSaved);
    }
  }

  saveDevice(deviceToBeSaved: Device): void {
    let deviceResidentId = this.checkDeviceResidentId(this.devEditForm.value.deviceResidentId);

    // Update the device and then get the updated device info from the API to ensure that the data is synced with the server
    this.api.updateDevice(deviceToBeSaved)
      .pipe(
        concatMap(() => this.api.getDeviceInfo(deviceToBeSaved.id)),
        tap(async (savedDevice: Device) => {
          // Reload alarm routes if device location has changed
          if (this.device.locationId !== savedDevice.locationId) {
            await this.reloadAlarmRoutes();
          }

          // If save was successfull replace original device with saved one and close edit mode
          this.device = savedDevice;
          this.editMode = false;

          // Link resident to device or unset
          if (this.shouldLinkResidentToDevice(deviceResidentId)) {
            await this.linkResidentToDevice(this.selectedResident);

            // Show update on device info
            this.device.residentDisplayName = this.deviceResidentDisplayName;
          }

          this.resetForm();
          this.updateDev.emit(this.device);
        }),
        catchError(err => this.handleSaveDeviceError(err))
      )
      .subscribe();
  }

  reloadAlarmRoutes(): Promise<void> {
    return this.getAlarmRoutes().then(_ => {
      this.parseAlarmRoutes();
      this.activationsParsed = true;
    });
  }

  shouldLinkResidentToDevice(deviceResidentId): boolean {
    return this.residents && this.residents.length > 0 && deviceResidentId && this.currentDeviceResidentId != deviceResidentId[0];
  }

  handleSaveDeviceError(err): Observable<any> {
    this.editMode = false;
    this.updateError.emit(true);
    // You could add more error handling here
    return of(null);
  }


  async linkResidentToDevice(selectedResident) {
    if (this.devEditForm.value.deviceResidentId == 0) {
      if (typeof this.currentDeviceResidentId !== 'undefined')
        await this.unsetResidentDevice();
    } else {
      await this.setResidentDevice(selectedResident);
    }
  }

  async setResidentDevice(selectedResident) {
    // Check if deviceResidentId is defined and has at least one element
    if (this.devEditForm.value.deviceResidentId && this.devEditForm.value.deviceResidentId.length > 0) {
      // Send residentDisplayInfo as info element which changes and is to be saved in device movement history
      const data = { residentId: this.devEditForm.value.deviceResidentId[0], deviceId: this.device.id, residentDisplayInfo: selectedResident.title };
      [this.currentDeviceResidentId, this.deviceResidentDisplayName] = await new Promise(resolve => {
        this.api.setResidentDevice(data).subscribe(res => {
          resolve([this.devEditForm.value.deviceResidentId[0], selectedResident.title]);
        }, err => {
          resolve([this.devEditForm.value.deviceResidentId[0], ""]);
          this.updateError.emit(true);
        });
      });
    } else {
      // console.error('deviceResidentId is null or does not have any elements');
    }
  }

  async unsetResidentDevice() {
    const data = { residentId: this.currentDeviceResidentId, deviceId: this.device.id, locationId: this.device.locationId };

    [this.currentDeviceResidentId, this.deviceResidentDisplayName] = await new Promise(resolve => {
      this.api.unsetResidentDevice(data).subscribe(res => {
        resolve([this.currentDeviceResidentId, ""]);
      }, err => {
        resolve([this.devEditForm.value.deviceResidentId[0], ""]);
        this.updateError.emit(true);
      });
    });
  }

  getActivationName(group: number, node: number, customAct: string): string {
    return this.helper.getActivationName(group, node, this.device.deviceType, customAct, this.device.connectionTimeLimit);
  }

  parseNetworkSurveyResults(resultArray: DeviceSignalLevel[]): void {
    //Loop through all objects in result array and group all operators together with signal qualities
    resultArray.forEach(e => {
      let curOperator: string = e.operator;
      let curNetworkMode: string = e.networkMode;
      let found: boolean = false;
      //Check that of same operator is already in result list with same date
      for (let i = 0; i < this.networkSurveyResults.length; i++) {
        if (this.networkSurveyResults[i].operator === curOperator) {
          //If operator was found for same date add network mode and signal level in it
          this.networkSurveyResults[i].signals[curNetworkMode] = e.signalLevel;
          found = true;
          break;
        }
      }
      //If operator was not yet in the list add it in it
      if (!found) {
        let data = {
          operator: curOperator,
          logTime: e.logTime,
          mccMnc: e.mccMnc,
          signals: {}
        }
        //Include signal level of the current result object
        data.signals[curNetworkMode] = e.signalLevel;
        this.networkSurveyResults.push(data);
      }
    });
  }


  // Groups activations by activation name
  parseDeviceActivations(): void {
    // Clear current activationsMap
    this.activationsMap = new Map();
    for (const activation of this.device.deviceActivations) {
      const name = this.getActivationName(activation.activationGroup, activation.activationNode, activation.customActivationName);
      if (this.activationsMap.has(name)) {
        this.activationsMap.get(name).push(activation);
      } else {
        this.activationsMap.set(name, [activation]);
      }
    }

    //Extract activation names. Used in template.
    this.activationNames = Array.from(this.activationsMap.keys());
  }

  parseAlarmRoutes() {
    if (this.user.locationId.startsWith('C0')) {
      this.availableAlarmRouteLocations = JSON.parse(JSON.stringify(this.alarmRouteLocations));
    } else {
      this.availableAlarmRouteLocations = [this.alarmRouteLocations.find(location => location.locationId === this.user.locationId)];
    }
  }

  async getAlarmRoutes(): Promise<void> {
    return new Promise<void>(resolve => this.api.getAlarmRoutesForDevice(this.device.id).subscribe(res => {
      this.alarmRouteLocations = res;
      resolve();
    }));
  }

  // @TODO Check if customer has Resident Registry enabled.
  // Y: Show dropdown list in Device edit to select a resident
  // N: Hide dropdown list and let user enter Residents name manually
  isResidentRegistryEnabled(): boolean {
    return false;
  }

  isFeatureEnabled(feature: string): boolean {
    let features = JSON.parse(localStorage.getItem('features'));
    //Check that customer has features enabled and if current feature is in that list
    if (features && features.indexOf(feature) !== -1) {
      return true;
    }
    return false;
  }

  isLock(): boolean {
    if (this.device.deviceType === '32-9' || //Danalock
      this.device.deviceType === '32-10' || //Masterlock
      this.device.deviceType === '32-11' || //Salto lock
      this.device.deviceType === '32-13') //Virtual lock
    {
      return true;
    }
    return false;
  }

  generateExternalPassword(): void {
    //Password is device ID encrypted with AES256
    this.api.generateExternalPassword(this.device.id).subscribe(res => {
      this.devEditForm.patchValue({
        externalPassword: res
      });
      this.devEditForm.markAsDirty();
    });
  }

  ngOnChanges() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.parseDeviceActivations();
    this.createForms();
    this.getAlarmRoutes().then(_ => {
      this.parseAlarmRoutes();
      this.activationsParsed = true;
    });
    this.editMode = false;
    //Separate signal levels and network survey results for own lists
    let networkSurveyResultsRaw: DeviceSignalLevel[] = [];
    if (this.device.signalLevels) {
      this.device.signalLevels.forEach(e => {
        if (e.networkSurvey) {
          networkSurveyResultsRaw.push(e);
        } else {
          this.signalLevels.push(e);
        }
      });
      this.parseNetworkSurveyResults(networkSurveyResultsRaw);
    }
    // Save the current resident id for later use.
    this.currentDeviceResidentId = this.device.residentId;

  }

  signalLevelSub: Subscription;
  noSignalStrengthSub: Subscription;
  translated_SIGNAL_LEVEL: string = "";
  translated_NO_SIGNAL_STRENGTH: string = "";

  ngOnInit() {

    // console.log(this.residents)
    // console.log(this.locations)



    // SIGNAL_LEVEL: ${level}` : "Signal strength not provided."

    this.signalLevelSub = this.translate.get("SIGNAL_LEVEL").subscribe(
      data => this.translated_SIGNAL_LEVEL = data
    )

    this.noSignalStrengthSub = this.translate.get("NO_SIGNAL_STRENGTH").subscribe(
      data => this.translated_NO_SIGNAL_STRENGTH = data
    )
    this.residentSelect.residentList = this.mapResidents(this.residents);
  }

  mapResidents(residents: DeviceResidentCompany[]) {
    if (!residents) {
      return [];
    }

    const residentList = residents.map(obj => {
      let SSNumber = obj.ssNumber ? `(${obj.ssNumber})` : "";

      let nameString = obj.displayName + '\xa0' + SSNumber;
      let paddingLength = Math.max(0, 35 - nameString.length);
      let padding = '\xa0'.repeat(paddingLength);

      let a: ResidentSelect = {
        id: obj.residentId,
        name: nameString + padding
      };

      return a;
    });

    residentList.unshift({ id: 0, name: "" });
    return residentList;
  }

  onDeviceUpdateSuccess(updatedDevice: any) {
    this.device = updatedDevice
  }

  handleError() {
    this.updateError.emit(true);
  }

  onSignalLevelsUpdate(data: any) {
    this.signalLevels = data
  }
}
