import { Subscription } from "rxjs";
import { StatisticsService } from "app/services/statistics.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { IAlertStatistics } from "app/models/statistics.model";
import { NgIf } from "@angular/common";
import { AlertsByLocationComponent } from "../../alerts-by-location/alerts-by-location.component";
import { AlertsPerAlertTypeComponent } from "../../alerts-per-alert-type/alerts-per-alert-type.component";
import { AlertsPerDayComponent } from "../../alerts-per-day/alerts-per-day.component";
import { AverageAlertDurationsComponent } from "../../average-alert-durations/average-alert-durations.component";
import { AlertsPerCareRecipientComponent } from "../../alerts-per-care-recipient/alerts-per-care-recipient.component";
import { AlertsPerHourComponent } from "../../alerts-per-hour/alerts-per-hour.component";

@Component({
  selector: "alerts-tab",
  templateUrl: "./alerts-tab.component.html",
  styleUrls: ["./alerts-tab.component.css"],
  standalone: true,
  imports: [
    NgIf,
    AlertsByLocationComponent,
    AlertsPerAlertTypeComponent,
    AlertsPerDayComponent,
    AlertsPerHourComponent,
    AverageAlertDurationsComponent,
    AlertsPerCareRecipientComponent,
  ],
})
export class AlertsTabComponent implements OnInit, OnDestroy {
  constructor(public statistics: StatisticsService) {}

  subscription: Subscription;
  alertStatisticsData: IAlertStatistics;

  ngOnInit() {
    this.subscription = this.statistics.alertStatistics$.subscribe((res) => {
      this.alertStatisticsData = this.statistics.alertStatistics = res;
      this.statistics.alertsTabDataLoaded = true;
    });
    this.statistics.fetchAlertStatistics$.next();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
