<h3>{{ "NAV_DEVICE_MANAGEMENT" | translate }}</h3>

<div class="pt-3">
    <div class="toolbar-row-grid">
        <!-- First row's buttons -->
        <div class="d-flex">
            <!-- Add new device -->
            <button
                *ngIf="user.roleLevel >= 100"
                id="addDeviceBtn"
                class="btn btn-primary"
                (click)="addNewBase()">
                <span class="fa-light fa-fw fa-plus"></span>
                {{ "ADD_NEW_DEVICE" | translate }}
            </button>

            <!-- Add new location -->
            <button
                *ngIf="user.roleLevel >= 100"
                id="addLocationBtn"
                class="btn btn-outline-primary"
                (click)="addNewLocation()">
                <span class="fa-light fa-fw fa-plus"></span>
                {{ "ADD_NEW_LOCATION" | translate }}
            </button>

            <!-- Download locations CSV -->
            <button
                *ngIf="deviceList"
                class="btn btn-outline-primary"
                (click)="exportDeviceList()">
                <span class="fa-light fa-download"></span>
                {{ "DOWNLOAD_CSV" | translate }}
            </button>
        </div>

        <div class="search">
            <div class="flex-grow mr-2">
                <input
                  type="text"
                  #deviceSearchInput
                  class="form-control border border-secondary"
                  placeholder="{{ 'SEARCH' | translate }}... "
                  (keydown.enter)="onEnterKeydown($event)"
                />
            </div>
            <button id="deviceManagementSearch" class="btn btn-primary" #deviceSearchButton>
                <span class="fa-light fa-magnifying-glass"></span>
                {{ "SEARCH" | translate }}
            </button>
        </div>
    </div>

    <!-- Second row's buttons -->
    <div class="toolbar-row-flex">
        <!-- Show or hide expanding panels -->
        <button *ngIf="deviceList && !locationsCollapsed && !devicesCollapsed && !manuallyCollapsed"
            class="btn btn-outline-dark disabled toggleHideBtn">
            <span class="fa-light fa-fw fa-minus"></span>
        </button>

        <button
            *ngIf="deviceList && (locationsCollapsed || devicesCollapsed || manuallyCollapsed)"
            class="btn btn-outline-primary toggleHideBtn"
            matTooltip="{{ 'TOOLTIP_COLLAPSE_DEVICES' | translate }}"
            (click)="handleCollapsing(false)">
            <span class="fa-light fa-fw fa-minus"></span>
        </button>

        <button *ngIf="deviceList && locationsCollapsed && devicesCollapsed && !manuallyCollapsed"
            class="btn btn-outline-dark disabled toggleExpandBtn">
            <span class="fa-light fa-fw fa-plus"></span>
        </button>

        <button
            *ngIf="deviceList && (!locationsCollapsed || !devicesCollapsed || manuallyCollapsed)"
            class="btn btn-outline-primary toggleExpandBtn"
            matTooltip="{{ 'TOOLTIP_EXPAND_DEVICES' | translate }}"
            (click)="handleCollapsing(true)">
            <span class="fa-light fa-fw fa-plus"></span>
        </button>

        <button
            *ngIf="deviceList && this.user.roleLevel >= 50"
            id="showEmptyLocBtn"
            class="btn btn-outline-primary"
            matTooltip="{{ 'TOOLTIP_EMPTY_LOCATIONS' | translate }}"
            (click)="searchDevice.showEmpty = !searchDevice.showEmpty">
            <span [ngClass]="searchDevice.showEmpty ? 'fa-regular fa-square-check' : 'fa-light fa-square'"></span>
            {{ "SHOW_EMPTY_LOCATIONS" | translate }}
        </button>

        <!-- Import locations CSV -->
        <button
            *ngIf="user.roleLevel >= 100 && digitalKeyEnabled"
            class="btn btn-outline-primary"
            (click)="showLockImportCard()">
            <span class="fa-light fa-fw fa-upload"></span>
            {{ "IMPORT_LOCKS_CSV" | translate }}
        </button>

        <button *ngIf="user.roleLevel >= 200" class="btn btn-primary" id="add-everwatch-button" (click)="addEverwatch()">
            <span class="fa-light fa-fw fa-plus"></span>
            {{ ("ADD_NEW" | translate) + ' EverWatch' }}
        </button>

        <button *ngIf="user.roleLevel >= 200" class="btn btn-primary" id="add-nightowl-button" (click)="addNightowl()">
            <span class="fa-light fa-fw fa-plus"></span>
            {{ ("ADD_NEW" | translate) + ' Nightowl' }}
        </button>
    </div>

    <div class="row p-2">
        <div [ngClass]="editMode ? ['col-lg-6', 'd-none', 'd-xl-block'] : 'col-lg-12'">
            <div *ngIf="!deviceListRaw && devicesLoaded">
                {{ "NO_DEVICES" | translate }}
            </div>

            <div *ngIf="generalInformationText" class="alert alert-success" role="alert">
                {{ generalInformationText }}
            </div>

            <!--Key listing-->
            <div *ngIf="keyList && keyList.length > 0" class="card">
                <div class="card-header cursorPointerHover" (click)="keyListCollapse = !keyListCollapse">
                    <h5>
                        <span class="fa-fw mr-2"
                            [ngClass]="keyListCollapse ? 'fa-light fa-key' : 'fa-solid fa-key'"></span>
                        <span class="mr-3">{{ "KEYS" | translate}}</span>
                    </h5>

                    <small>{{ "NR_OF_KEYS" | translate }}:{{ keyList.length }}</small>
                </div>

                <div class="card-body" *ngIf="keyListCollapse">
                    <table
                        class="table table-hover deviceListTable keyListTable"
                        matSort
                        (matSortChange)="sortKeys($event)">
                        <thead>
                            <tr>
                                <th style="width: 1%"></th>
                                <th mat-sort-header="id">
                                    {{ "SERIAL_NUMBER" | translate }}
                                </th>
                                <th mat-sort-header="name">
                                    {{ "DEVICE_NAME" | translate }}
                                </th>
                                <th mat-sort-header="deviceType">
                                    {{ "DEVICE_TYPE" | translate }}
                                </th>
                                <th mat-sort-header="lowBatteryWarning">
                                    {{ "DEVICE_STATUS" | translate }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                *ngFor="let key of keyList"
                                class="cursorPointer"
                                [ngClass]="{'table-active': key === selectedDev}"
                                (click)="editDevice(key.id)">
                                <td>
                                    <span class="fa-fw" [ngClass]="getDeviceIconClass(key)"></span>
                                </td>
                                <td>{{ key.id }}</td>
                                <td>{{ key.name }}</td>
                                <td>
                                    {{ getDeviceType(key.deviceType) }}
                                </td>
                                <td>
                                    <span
                                        *ngIf="key.lowBatteryWarning"
                                        title="{{ 'BATTERY_STATUS_WARNING'| translate }}"
                                        class="fa-solid fa-fw fa-lg fa-battery-slash text-danger"></span>
                                    <span *ngIf="!key.keyEnabled"
                                        class="fa-light fa-fw fa-octagon-minus text-danger"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!--Device listing-->
            <ng-container *ngFor="let location of filteredDeviceList; let i = index">
                <div *ngIf="location.devices?.length > 0 || searchDevice.showEmpty" class="card">
                    <div
                        class="card-header cursorPointerHover d-flex justify-content-between"
                        matTooltip="{{ location.name === mobileDevicesLocationUIName ? ('TOOLTIP_EXPAND_MOBILE_APPLICATIONS' | translate) : ('TOOLTIP_EXPAND_DEVICE_LIST' | translate)}}"
                        (click)="location.collapseState = !location.collapseState; changeCollapseState(location.id, location.collapseState)">
                        <div class="float-left">
                            <h5>
                                <span
                                    *ngIf="location.name !== mobileDevicesLocationUIName"
                                    class="fa-fw mr-2"
                                    [ngClass]="location.collapseState ? 'fa-light fa-house' : 'fa-solid fa-house'"
                                    matTooltip="{{ 'TOOLTIP_LOCATION' | translate }}"></span>

                                <span
                                    *ngIf="location.name === mobileDevicesLocationUIName"
                                    class="fa-fw mr-2"
                                    [ngClass]="location.collapseState ? 'fa-light fa-mobile-screen-button' : 'fa-solid fa-mobile-screen-button'"
                                    matTooltip="{{ 'TOOLTIP_MOBILE_APPLICATIONS' | translate }}"></span>

                                <span style="word-break: break-all">{{ location.name }}</span>

                                <span *ngIf="location.networkFailureState"
                                    class="ml-3 text-danger locationNetworkFailureText">
                                    <span class="fa-light fa-fw fa-wifi-slash"></span>
                                    {{ "NETWORK_FAILURE" | translate}}
                                </span>
                            </h5>
                            <small>{{ "NR_OF_BASES" | translate }}: {{ location.baseCount ?? 0 }} / {{
                                "NR_OF_DEVICES" | translate }}: {{ location.deviceCount ?? 0 }}</small>
                        </div>

                        <span
                            *ngIf="location.name !== mobileDevicesLocationUIName"
                            class="fa-light fa-lg fa-pen-to-square mr-2 cursorPointerHover float-right"
                            style="margin-top: 18px"
                            matTooltip="{{ 'TOOLTIP_EDIT_LOCATION' | translate }}"
                            (click)="editLocation(location, $event)"></span>
                    </div>

                    <div *ngIf="location.collapseState" class="card-body">
                        <table
                            id="deviceListTable"
                            *ngIf="location.devices?.length > 0;else emptyLocation"
                            class="table table-borderless table-hover table-responsive deviceListTable"
                            (matSortChange)="sortDeviceList($event, i)"
                            matSort>
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th mat-sort-header="id">
                                        <span class="text-left">
                                            {{ "SERIAL_NUMBER" | translate }}
                                        </span>
                                    </th>
                                    <th mat-sort-header="name">
                                        <span class="text-left">
                                            {{ "DEVICE_NAME" | translate }}
                                        </span>
                                    </th>
                                    <th mat-sort-header="deviceType" class="w-25">
                                        <span class="text-left">
                                            {{ "DEVICE_TYPE" | translate }}
                                        </span>
                                    </th>
                                    <th *ngIf="editMode"></th>
                                    <th *ngIf="deviceResidents?.length > 0 && !editMode"
                                        mat-sort-header="resident">
                                        <span class="text-left">
                                            {{ "RESIDENT" | translate }}
                                        </span>
                                    </th>
                                    <th
                                        *ngIf="!editMode"
                                        mat-sort-header="onlineStatus"
                                        class="d-none d-lg-table-cell d-xl-table-cell">
                                        <span class="text-left">
                                            {{ "DEVICE_LAST_CONTACT" | translate }}
                                        </span>
                                        <span class="fa fa-fw fa-circle-info"
                                            matTooltip="{{ 'TOOLTIP_DEVICE_LAST_CONNECTION'| translate }}">
                                        </span>
                                    </th>
                                    <th *ngIf="user.roleLevel >= 50 && !editMode">
                                        {{ "DEVICE_ADDITIONAL_INFO_SHORT" | translate }}
                                    </th>
                                    <th *ngIf="user.roleLevel >= 50 && !editMode">
                                        {{ "DEVICE_ADDITIONAL_ALARM_INFO" | translate }}
                                    </th>
                                    <th style="width: 1%"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let dev of location.devices;let i = index">
                                    <tr
                                        class="border-top cursorPointer"
                                        [ngClass]="{ collapsedTr: dev.collapseState && dev.pairedDevices, 'table-active': dev?.id === selectedDev?.id, baseHasPaired: dev.pairedDevices }"
                                        (click)="editDevice(dev.id)">
                                        <td>
                                            <div class="d-flex flex-nowrap align-items-baseline deviceListStatus">
                                                <span *ngIf="dev.lowBatteryWarning"
                                                    title="{{ 'BATTERY_STATUS_WARNING' | translate }}"
                                                    class="fa-solid fa-fw fa-lg fa-battery-slash text-danger"></span>

                                                <span
                                                    *ngIf="dev.tasksToBase"
                                                    title="{{ 'DEVICE_PARAM_TO' | translate }}"
                                                    class="fa-light fa-down-to-line fa-lg text-warning"></span>

                                                <span
                                                    *ngIf="dev.tasksFromBase"
                                                    title="{{ 'DEVICE_PARAM_FROM' | translate }}"
                                                    class="fa-light fa-up-from-line fa-lg text-warning"></span>

                                                <span
                                                    *ngIf="dev.toBeDeleted"
                                                    title="{{ 'DEVICE_MARKED_DELETE' | translate }}"
                                                    class="fa-light fa-octagon-minus text-danger"></span>

                                                <span
                                                    *ngIf="dev.softwareUpdateOngoing"
                                                    title="{{ 'FIRMWARE_UPDATE_ONGOING' | translate }}"
                                                    class="fa-light fa-arrows-rotate text-warning"></span>

                                                <div [ngSwitch]="dev.connectionType"
                                                    *ngIf="dev.id.substr(0, 2) == '31'"
                                                    matTooltip="{{service.getActiveConnectionInfo(dev.signalLevels, dev.connectionType)}}"
                                                    [matTooltipPosition]="'before'">
                                                    <!-- service.getColorClass(dev.signalLevels) -->
                                                    <span *ngSwitchCase="0"
                                                        [ngClass]="'text-dark'"
                                                        class="fa-solid fa-signal"></span>
                                                    <span *ngSwitchCase="1" [ngClass]="'text-dark'"
                                                        class="fa-solid fa-network-wired"></span>
                                                    <span *ngSwitchCase="2" [ngClass]="'text-dark'"
                                                        class="fa-solid fa-wifi"></span>
                                                </div>
                                            </div>
                                        </td>

                                        <td innerHTML="{{ dev.id | highlight : deviceFilter }}"></td>
                                        <td innerHTML="{{ dev.name | highlight : deviceFilter }}"></td>
                                        <td>
                                            <span class="fa-light fa-fw mr-1"
                                                [ngClass]="getDeviceIconClass(dev)"></span>
                                            <span
                                                innerHTML="{{ getDeviceType(dev.deviceType) | wrapHyphen | highlight : deviceFilter }}">
                                            </span>
                                        </td>
                                        <td *ngIf="editMode" class="d-none d-lg-table-cell d-xl-table-cell">
                                            <div *ngIf="dev.deviceType !== '06-1'">
                                                <i
                                                    *ngIf="dev.onlineStatus === 'Offline' && dev.lastConnection !== '0001-01-01T00:00:00Z' && !onlineInLastDay(dev.lastConnection)"
                                                    class="fa-solid fa-fw fa-square-xmark noConnection"></i>

                                                <i
                                                    *ngIf="dev.onlineStatus === 'Offline' && onlineInLastDay(dev.lastConnection)"
                                                    class="fa-solid fa-fw fa-triangle-exclamation noConnectionInLast24"></i>

                                                <i
                                                    *ngIf="dev.onlineStatus === 'Online' && dev.lastConnection !== '0001-01-01T00:00:00Z'"
                                                    class="fa-solid fa-fw fa-circle-check deviceOnlineState"></i>
                                            </div>

                                            <!--Timestamps for Vegas are always hidden since they are incorrect-->
                                            <div *ngIf="dev.deviceType === '06-1'">-</div>
                                        </td>
                                        <td *ngIf="deviceResidents?.length > 0 && !editMode"
                                            innerHTML="{{ dev.residentDisplayName | highlight : deviceFilter }}">
                                        </td>
                                        <td *ngIf="!editMode" class="d-none d-lg-table-cell d-xl-table-cell">
                                            <div *ngIf="dev.deviceType !== '06-1'">
                                                <i
                                                    *ngIf="dev.onlineStatus === 'Offline' && dev.lastConnection !== '0001-01-01T00:00:00Z' && !onlineInLastDay(dev.lastConnection)"
                                                    class="fa-solid fa-fw fa-square-xmark noConnection"></i>

                                                <i
                                                    *ngIf="dev.onlineStatus === 'Offline' && onlineInLastDay(dev.lastConnection)"
                                                    class="fa-solid fa-fw fa-triangle-exclamation noConnectionInLast24"></i>

                                                <i
                                                    *ngIf="dev.onlineStatus === 'Online' && dev.lastConnection !== '0001-01-01T00:00:00Z'"
                                                    class="fa-solid fa-fw fa-circle-check deviceOnlineState"></i>

                                                {{ dev.lastConnection | moment : "l LTS" }}
                                            </div>

                                            <!--Timestamps for Vegas are always hidden since they are incorrect-->
                                            <div *ngIf="dev.deviceType === '06-1'">-</div>
                                        </td>
                                        <td *ngIf="user.roleLevel >= 50 && !editMode"
                                            class="cutLongText"
                                            innerHTML="{{ dev.additionalInfo | highlight : deviceFilter }}">
                                        </td>
                                        <td *ngIf="user.roleLevel >= 50 && !editMode"
                                            class="cutLongText"
                                            innerHTML="{{ dev.additionalAlarmInfo | highlight : deviceFilter }}">
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="dev.pairedDevices"
                                                class="fa-solid fa-fw cursorPointer"
                                                [ngClass]="dev.collapseState ? 'fa-chevron-down' : 'fa-chevron-right'"
                                                (click)="collapseDeviceRow(dev, $event)">
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="let pairedDev of dev.pairedDevices; let last = last"
                                        [hidden]="!dev.collapseState"
                                        class="pairedDevices cursorPointer"
                                        [ngClass]="{ pairedDevicesLast: last, 'table-active': pairedDev?.id === selectedDev?.id }"
                                        (click)="editDevice(pairedDev.id)">

                                        <td
                                            class="deviceListStatus">
                                            <span *ngIf="pairedDev.lowBatteryWarning"
                                                title="{{ 'BATTERY_STATUS_WARNING' | translate }}"
                                                class="fa-solid fa-fw fa-lg fa-battery-slash text-danger"></span>

                                            <span *ngIf="pairedDev.tasksToBase"
                                                title="{{ 'DEVICE_PARAM_TO' | translate }}"
                                                class="fa-light fa-down-to-line fa-lg text-warning"></span>

                                            <span *ngIf="pairedDev.tasksFromBase"
                                                class="fa-light fa-up-from-line fa-lg text-warning"
                                                title="{{ 'DEVICE_PARAM_FROM' | translate }}"></span>

                                            <span *ngIf="pairedDev.toBeDeleted"
                                                class="fa-light fa-octagon-minus text-danger"
                                                title="{{ 'DEVICE_MARKED_DELETE' | translate }}"></span>

                                            <span
                                                *ngIf="pairedDev.replaceDevice && pairedDev.replaceDevice !== '00000000' && pairedDev.replaceDevice !== '0'"
                                                title="{{ 'DEVICE_MARKED_REPLACE' | translate }}"
                                                class="fa-light fa-arrow-right-arrow-left text-warning ml-2"></span>

                                            <span *ngIf="pairedDev.softwareUpdateOngoing"
                                                title="{{ 'FIRMWARE_UPDATE_ONGOING' | translate }}"
                                                class="fa-light fa-arrows-rotate text-warning ml-2"></span>

                                            <div [ngSwitch]="pairedDev.connectionType"
                                                *ngIf="pairedDev.id.substr(0, 2) == '31'"
                                                matTooltip="{{service.getActiveConnectionInfo(pairedDev.signalLevels, pairedDev.connectionType)}}"
                                                [matTooltipPosition]="'before'">
                                                <!-- service.getColorClass(pairedDev.signalLevels) -->
                                                <span *ngSwitchCase="0" [ngClass]="'text-dark'"
                                                    class="fa-solid fa-signal"></span>
                                                <span *ngSwitchCase="1" [ngClass]="'text-dark'"
                                                    class="fa-solid fa-network-wired"></span>
                                                <span *ngSwitchCase="2" [ngClass]="'text-dark'"
                                                    class="fa-solid fa-wifi"></span>
                                            </div>
                                        </td>
                                        <td innerHTML="{{ pairedDev.id | highlight : deviceFilter }}"></td>
                                        <td innerHTML="{{ pairedDev.name | highlight : deviceFilter }}"></td>
                                        <td>
                                            <span class="fa-fw mr-1"
                                                [ngClass]="getDeviceIconClass(pairedDev)">
                                            </span>
                                            <span
                                                innerHTML="{{ getDeviceType(pairedDev.deviceType) | wrapHyphen | highlight : deviceFilter }}">
                                            </span>
                                        </td>
                                        <td *ngIf="editMode" class="d-none d-lg-table-cell d-xl-table-cell">
                                            <i
                                                class="fa-solid fa-fw fa-square-xmark noConnection"
                                                *ngIf="
                                                        pairedDev.onlineStatus === 'Offline' &&
                                                        pairedDev.lastConnection !== '0001-01-01T00:00:00Z' &&
                                                        !onlineInLastDay(pairedDev.lastConnection)"></i>

                                            <i
                                                *ngIf="pairedDev.onlineStatus === 'Offline' && onlineInLastDay(pairedDev.lastConnection)"
                                                class="fa-solid fa-fw fa-triangle-exclamation noConnectionInLast24"></i>

                                            <i
                                                *ngIf="pairedDev.onlineStatus === 'Online' && pairedDev.lastConnection !== '0001-01-01T00:00:00Z'"
                                                class="fa-solid fa-fw fa-circle-check deviceOnlineState"></i>
                                        </td>
                                        <td *ngIf="deviceResidents?.length > 0 && !editMode"
                                            innerHTML="{{ pairedDev.residentDisplayName | highlight : deviceFilter }}">
                                        </td>
                                        <td *ngIf="!editMode" class="d-none d-lg-table-cell d-xl-table-cell">
                                            <i
                                                class="fa-solid fa-fw fa-square-xmark noConnection"
                                                *ngIf="
                                                        pairedDev.onlineStatus === 'Offline' &&
                                                        pairedDev.lastConnection !== '0001-01-01T00:00:00Z' &&
                                                        !onlineInLastDay(pairedDev.lastConnection)"></i>

                                            <i
                                                *ngIf="pairedDev.onlineStatus === 'Offline' && onlineInLastDay(pairedDev.lastConnection)"
                                                class="fa-solid fa-fw fa-triangle-exclamation noConnectionInLast24"></i>

                                            <i
                                                *ngIf="pairedDev.onlineStatus === 'Online' && pairedDev.lastConnection !== '0001-01-01T00:00:00Z'"
                                                class="fa-solid fa-fw fa-circle-check deviceOnlineState"></i>

                                            {{ pairedDev.lastConnection | moment : "l LTS" }}
                                        </td>
                                        <td *ngIf="user.roleLevel >= 50 && !editMode"
                                            class="cutLongText"
                                            innerHTML="{{ pairedDev.additionalInfo | highlight : deviceFilter }}">
                                        </td>
                                        <td *ngIf="user.roleLevel >= 50 && !editMode"
                                            class="cutLongText"
                                            innerHTML="{{ pairedDev.additionalAlarmInfo | highlight : deviceFilter }}">
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>

                        <ng-template #emptyLocation>
                            <div class="mb-2">
                                {{ "LOCATION_NO_DEVICES" | translate }}
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-container>


            <div class="justify-content-center">
                <div *ngIf="!deviceList && !devicesLoaded">
                    <img
                        class="fadeLoader centerLoader logoSmall"
                        src="assets/img/loading_logo.svg" />
                </div>
            </div>
        </div>

        <!-- Card for editing existing device. Show if editMode is true and device with prefix other than F0 and class 1 is selected -->
        <div *ngIf="editMode && selectedDev && selectedDev.deviceType !== 'F0-1'" class="col-xl-6">
            <div class="card stickyCard" style="width: 100%">
                <div class="card-header-white header-underline" style="padding-bottom: 20px; padding-top: 10px">
                    <div style="position: relative; display: inline-block; top: 25%;">
                        <h6>
                            <span *ngIf="selectedDev.name; else devHeaderElse">
                                {{ selectedDev.name }} ({{ selectedDev.id}})
                            </span>
                            <ng-template #devHeaderElse>
                                <span>{{ selectedDev.id }}</span>
                            </ng-template>
                        </h6>
                    </div>

                    <div style="position: relative; display: inline-block; top: 25%;">
                        <span *ngIf="selectedDev.tasksToBase" title="{{ 'DEVICE_PARAM_TO' | translate }}"
                            class="fa-light fa-down-to-line fa-lg text-warning ml-2"></span>

                        <span *ngIf="selectedDev.tasksFromBase" title="{{ 'DEVICE_PARAM_FROM' | translate }}"
                            class="fa-light fa-up-from-line fa-lg text-warning ml-2"></span>

                        <span
                            *ngIf="selectedDev.toBeDeleted"
                            title="{{ 'DEVICE_MARKED_DELETE' | translate }}"
                            class="fa-light fa-octagon-minus text-danger ml-2"></span>

                        <span
                            title="{{ 'DEVICE_MARKED_REPLACE' | translate }}"
                            class="fa-light fa-arrow-right-arrow-left text-warning ml-2"
                            *ngIf="
                                selectedDev.id.substr(0, 2) !== '31' &&
                                selectedDev.replaceDevice &&
                                selectedDev.replaceDevice !== '00000000' &&
                                selectedDev.replaceDevice !== '0'">
                        </span>

                        <span
                            *ngIf="selectedDev.softwareUpdateOngoing"
                            title="{{ 'FIRMWARE_UPDATE_ONGOING' | translate }}"
                            class="fa-light fa-arrows-rotate text-warning ml-2"></span>
                    </div>

                    <span class="cursorPointer" (click)="editDevice(selectedDev.id)">
                        <span class="fa-light fa-xmark float-right"
                            style=" position: relative; margin-right: 15px; top: 30%;"></span>
                    </span>
                </div>

                <div class="card-body-white">
                    <div *ngIf="selectedDevInfoLoaded">
                        <div class="mb-2">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    <span class="editPageTab" [ngClass]="{ 'editPageTab-active': editModePage === 0 }"
                                        (click)="editModePage = 0">
                                        {{ "INFORMATION" | translate }}
                                    </span>
                                </li>

                                <li *ngIf="showDeviceSettings(selectedDevInfo) && user.roleLevel >= 100"
                                    matTooltip="{{ 'TOOLTIP_DEVICE_SETTINGS' | translate }}" class="list-inline-item">
                                    <span class="editPageTab" [ngClass]="{ 'editPageTab-active': editModePage === 3 }"
                                        (click)=" editModePage = 3; devInformationText = ''">
                                        {{ "SETTINGS" | translate }}
                                    </span>
                                </li>

                                <li *ngIf="user.roleLevel >= 50 && !deviceManagementEmpty"
                                    matTooltip="{{ 'TOOLTIP_DEVICE_CONTROL' | translate }}" class="list-inline-item">
                                    <span class="editPageTab" [ngClass]="{ 'editPageTab-active': editModePage === 1 }"
                                        (click)="editModePage = 1; devInformationText = ''">
                                        {{ "DEVICE_CONTROL" | translate}}
                                    </span>
                                </li>

                                <li
                                    class="list-inline-item"
                                    *ngIf="
                                        selectedDev.deviceType === '31-7' ||
                                        selectedDev.deviceType === '40-1' ||
                                        selectedDev.deviceType === '31-8'">
                                    <span class="editPageTab" [ngClass]="{ 'editPageTab-active': editModePage === 2 }"
                                        (click)="editModePage = 2; devInformationText = ''">
                                        {{ "DISPLAY_CONFIGURATION" | translate }}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="devInformationText" class="alert alert-success">
                            {{ devInformationText }}
                        </div>
                        <div *ngIf="devUpdateError" class="alert alert-danger">
                            {{ "ERROR" | translate }}
                        </div>

                        <device-info
                            [device]="selectedDevInfo"
                            [locations]="locationList"
                            [residents]="deviceResidents"
                            (updateDev)="updateDev($event)"
                            (updateError)="updateError($event)"
                            *ngIf="editModePage === 0"></device-info>

                        <device-settings
                            [device]="selectedDevInfo"
                            *ngIf="editModePage === 3"></device-settings>

                        <device-control
                            [device]="selectedDevInfo"
                            [pairedDevices]="selectedDev.pairedDevices"
                            [basestationList]="basestationList"
                            (isDeviceManagementEmpty)="isDeviceManagementEmpty($event)"
                            (nfcMoved)="nfcMoved($event)"
                            (deviceReplaced)="deviceReplaced($event)"
                            (deviceForceDeleted)="deviceForceDeleted($event)"
                            (deviceDeleted)="deviceDeleted($event)"
                            (cancelDeviceDelete)="cancelDeviceDelete($event)"
                            (settingsTransferred)="settingsTransferred($event)"
                            (settingTransferCancelled)="settingTransferCancelled($event)"
                            (updateError)="updateError($event)"
                            *ngIf="editModePage === 1 || !isDeviceManagementEmptyChecked"
                            [hidden]="!isDeviceManagementEmptyChecked"></device-control>

                        <displayconfig
                            *ngIf="editModePage === 2"
                            [id]="selectedDev.id"></displayconfig>
                    </div>
                    <div *ngIf="!selectedDevInfoLoaded">
                        <img
                            class="fadeLoader centerLoader logoSmall"
                            src="assets/img/loading_logo.svg" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Card for editing existing key. Show it if editMode is true and device with prefix F0 and class 1 is selected -->
        <div *ngIf="editMode && selectedDev && selectedDev.deviceType === 'F0-1'" class="col-xl-6 col-lg-12">
            <div class="card stickyCard" style="padding-bottom: 10px">
                <div class="card-header-white header-underline">
                    <h6 class="white-header-text">
                        <span *ngIf="selectedDev.name; else devHeaderElse">
                            {{ selectedDev.name }} ({{ selectedDev.id }})
                        </span>
                        <ng-template #devHeaderElse>
                            <span>{{ selectedDev.id }}</span>
                        </ng-template>

                        <span class="float-right cursorPointer" (click)="editDevice(selectedDev.id)">
                            <span class="fa-light fa-xmark" style="margin-right: 15px"></span>
                        </span>
                    </h6>
                </div>

                <div class="card-body-white">
                    <div *ngIf="selectedDevInfoLoaded">
                        <div *ngIf="devInformationText" class="alert alert-success">
                            {{ devInformationText }}
                        </div>
                        <div *ngIf="devUpdateError" class="alert alert-danger">
                            {{ "ERROR" | translate }}
                        </div>

                        <device-key-info
                            [device]="selectedDevInfo"
                            (updateKey)="updateKey($event)"
                            (updateError)="updateError($event)"></device-key-info>
                    </div>
                    <div *ngIf="!selectedDevInfoLoaded">
                        <img
                            class="fadeLoader centerLoader logoSmall"
                            src="assets/img/loading_logo.svg" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Card for adding new device. Show it if editMode is set to true and no device or location is selected -->
        <div *ngIf="editMode && !selectedDev && !selectedLocation && !lockImport && showForm === ''" class="col-xl-6 col-lg-12">
            <device-add
                [locationList]="locationList"
                (closeAddFormEvent)="closeAddBaseForm()"
                (deviceAddedEvent)="deviceAdded($event)"></device-add>
        </div>
        <!--Card for editing location. Show it if editMode is true and no device is selected but location with id is selecter-->
        <div
            class="col-xl-6 col-lg-12"
            *ngIf="
            editMode &&
            !selectedDev &&
            selectedLocation &&
            selectedLocation.id">
            <location-info
                [location]="selectedLocation"
                (locationDeletedEvent)="locationDeleted($event)"
                (locationUpdateEvent)="locationUpdated($event)"
                (closeLocationCardEvent)="editLocation($event)">
            </location-info>
        </div>

        <!--Card for editing location. Show it if editMode is true and no device is selected but location without id is selected-->
        <div
            class="col-xl-6 col-lg-12"
            *ngIf="
            editMode &&
            !selectedDev &&
            selectedLocation &&
            !selectedLocation.id">
            <location-add
                [location]="selectedLocation"
                (locationAddedEvent)="locationAdded($event)"
                (closeLocationCardEvent)="editLocation($event)">
            </location-add>
        </div>

        <!--Card for lock CSV import-->
        <div class="col-xl-6 col-lg-12" *ngIf="lockImport">
            <device-import-lock-csv (closeLockImportCardEvent)="showLockImportCard()"></device-import-lock-csv>
        </div>

        <!-- Render only if the form is selected -->
        <div class="col-xl-6 col-lg-12" *ngIf="editMode && !selectedDev && showForm !== ''">
          <div [ngSwitch]="showForm" class="stickyCard">
            <!-- Card for adding new Nightowl -->
            <app-nightowl-form
              *ngSwitchCase="'nightowl'"
              [locations]="deviceList | extractLocations"
              (closeEvent)="showForm = ''; editMode = false"
              (savedEvent)="showForm = ''; deviceAdded($event)">
            </app-nightowl-form>

            <!-- Card for adding new EverWatch -->
            <app-everwatch-form
              *ngSwitchCase="'everwatch'"
              [locations]="deviceList | extractLocations"
              (closeEvent)="showForm = ''; editMode = false"
              (savedEvent)="showForm = ''; deviceAdded($event)">
            </app-everwatch-form>
          </div>
        </div>
    </div>
</div>
