import { DateAdapter } from "@angular/material/core";
import { STATISTICS_FILTER_SELECT_CONFIGS } from "app/shared/ss-multiselect-dropdown-settings.shared";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/services/api.service";
import { HelperService, ReportFilter, UiLang } from "app/services/helper.service";
import * as moment from "moment";
import { StatisticsService } from "app/services/statistics.service";
import { StatisticsDateOption } from "app/models/statistics.model";
import { NgClass, NgIf } from "@angular/common";
import { NgxBootstrapMultiselectModule } from "ngx-bootstrap-multiselect";
import { FormsModule } from "@angular/forms";
import { AlertsTabComponent } from "./tabs/alerts-tab/alerts-tab.component";
import { AlarmCenterTabComponent } from "./tabs/alarm-center-tab/alarm-center-tab.component";
import { CareRecipientsTabComponent } from "./tabs/care-recipients-tab/care-recipients-tab.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";

@Component({
  selector: "statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.css"],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgxBootstrapMultiselectModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    AlertsTabComponent,
    AlarmCenterTabComponent,
    CareRecipientsTabComponent,
  ],
})
export class StatisticsComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private api: ApiService,
    private helper: HelperService,
    public statistics: StatisticsService
  ) { }

  @ViewChild("dropdownBtn") dropdownBtn: ElementRef;

  private _selectedDateOption: StatisticsDateOption;
  selectedDateOptionText: string;

  selectedTab: "alerts" | "alarmCenter" | "careRecipients" = "alerts";
  filterSelects: ReportFilter = STATISTICS_FILTER_SELECT_CONFIGS;
  currentDate: Date = new Date();
  dateLimit: Date = moment().subtract(4, "months").toDate();
  showTimeOptions: boolean = false;
  EMAACEnabled: boolean = false;

  dateOptions: IDateOptions = {
    today: {
      value: StatisticsDateOption.Today,
      text: this.translate.instant("TODAY"),
    },
    yesterday: {
      value: StatisticsDateOption.YesterDay,
      text: this.translate.instant("YESTERDAY"),
    },
    lastWeek: {
      value: StatisticsDateOption.LastWeek,
      text: this.translate.instant("LAST_WEEK"),
    },
    lastMonth: {
      value: StatisticsDateOption.LastMonth,
      text: this.translate.instant("LAST_MONTH"),
    },
    last90Days: {
      value: StatisticsDateOption.Last90Days,
      text: this.translate.instant("LAST_90_DAYS"),
    },
    custom: {
      value: StatisticsDateOption.Custom,
      text: this.translate.instant("CUSTOM_DATES"),
    },
  };

  get selectedDateOption(): StatisticsDateOption {
    return this._selectedDateOption;
  }

  set selectedDateOption(option: IDateOption) {
    this.statistics.filters.setDates(option.value);
    this._selectedDateOption = option.value;
    this.selectedDateOptionText = option.text;
  }

  /**
   * Card dropdown hide handler
   * @param event Click event
   */
  @HostListener("document:click", ["$event"])
  handleHideDropdown(event: Event): void {
    // Check if clicked element is dropdown button or element inside the button
    const isDropdownBtn = this.dropdownBtn.nativeElement === event.target;
    const isBtnInnerEl = this.dropdownBtn.nativeElement.contains(event.target);

    // Hide the dropdown if the clicked element isn't any of the checked elements
    if (!isDropdownBtn && !isBtnInnerEl) {
      this.showTimeOptions = false;
    }
  }

  onDateSelect(selectedOption: IDateOption): void {
    this.selectedDateOption = selectedOption;
  }

  resetFilters(): void {
    this.statistics.filters.locations = [];
    this.statistics.filters.alertRoutes = [];
    this.statistics.filters.devices = [];
    this.statistics.filters.deviceTypes = [];
    this.statistics.filters.personnels = [];
    this.selectedDateOption = this.dateOptions.today;
  }

  onCustomEndDateSelect(event: any): void {
    const endDate = moment(event.value).endOf("d").utc().toDate();
    this.statistics.filters.endDate = endDate;
  }

  compareNames(a: any, b: any): number {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }

  getDeviceTypeNames(deviceTypes: string[]): { id: string; name: string }[] {
    const array = [];
    for (const deviceType of deviceTypes) {
      array.push({
        id: deviceType,
        name: this.api.getDeviceType(deviceType, true),
      });
    }
    return array;
  }

  onDeviceTypeFilterChange(event: any): void {
    this.statistics.filters.alertTypes = [];
    this.filterSelects.activations.filterList = [];
    let activations: any[] = this.helper.getActivationsForDeviceType(event[0]);
    activations.forEach((e) => {
      let activation: any = {
        id: `${e.group};${e.node};${e.deviceType}`,
        name: e.name,
      };
      this.filterSelects.activations.filterList.push(activation);
    });
  }

  initFilterSelects(): void {
    // Get select options for the filterSelects
    this.api.getReportFilteringOptions().subscribe((res) => {
      this.filterSelects.device.filterList = res.devices.sort(
        this.compareNames
      );
      this.filterSelects.location.filterList = res.locations.sort(
        this.compareNames
      );
      this.filterSelects.personnel.filterList = res.personnel.sort(
        this.compareNames
      );
      this.filterSelects.alertRoute.filterList = res.alertRoutes.sort(
        this.compareNames
      );
      this.filterSelects.deviceTypes.filterList = this.getDeviceTypeNames(
        res.deviceTypes
      ).sort(this.compareNames);
    });

    // Get translations for the select buttons
    this.translate
      .get([
        "DATE_FROM",
        "DATE_TO",
        "FILTER_CHECK_ALL",
        "FILTER_UNCHECK_ALL",
        "FILTER_CHECKED",
        "FILTER_ALL_SELECTED",
        "SEARCH",
        "DEVICE",
        "ALERT_ROUTE",
        "LOCATION",
        "BASESTATION",
        "STAFF",
        "CUSTOM_DEVICE_ID",
        "CUSTOM_BASESTATION_ID",
        "DEVICE_TYPE",
        "ACTIVATION",
        "EVENT_TYPE",
      ])
      .subscribe((t) => {
        const shared = {
          checkAll: t.FILTER_CHECK_ALL,
          uncheckAll: t.FILTER_UNCHECK_ALL,
          checked: t.FILTER_CHECKED,
          checkedPlural: t.FILTER_CHECKED,
          searchPlaceholder: t.SEARCH,
          allSelected: t.FILTER_ALL_SELECTED,
        };

        this.filterSelects.device.texts = {
          ...shared,
          ...{ defaultTitle: t.DEVICE },
        };

        this.filterSelects.alertRoute.texts = {
          ...shared,
          ...{ defaultTitle: t.ALERT_ROUTE },
        };

        this.filterSelects.location.texts = {
          ...shared,
          ...{ defaultTitle: t.LOCATION },
        };

        this.filterSelects.basestation.texts = {
          ...shared,
          ...{ defaultTitle: t.BASESTATION },
        };

        this.filterSelects.personnel.texts = {
          ...shared,
          ...{ defaultTitle: t.STAFF },
        };

        this.filterSelects.deviceTypes.texts = {
          ...shared,
          ...{ defaultTitle: t.DEVICE_TYPE },
        };

        this.filterSelects.activations.texts = {
          ...shared,
          ...{ defaultTitle: t.ACTIVATION },
        };

        this.filterSelects.eventTypes.texts = {
          ...shared,
          ...{ defaultTitle: t.EVENT_TYPE },
        };
      });

    // Init default default option
    this.selectedDateOption = this.dateOptions.today;
  }

  isFeatureEnabled(feature: string): boolean {
    let features: [string] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  exportJSON(): void {
    switch (this.selectedTab) {
      case "alerts":
        this.statistics.exportAlertStatisticsJSON();
        break;
      case "alarmCenter":
        this.statistics.exportEmaacStatisticsJSON();
        break;
      case "careRecipients":
        this.statistics.exportCareRecipientStatisticsJSON();
        break;
    }
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem("user"));
    user.language = localStorage.getItem("language");

    this.helper.setDateAdapterLocale(user.language as UiLang, this.dateAdapter);

    this.EMAACEnabled = this.isFeatureEnabled("EMAAC");

    this.initFilterSelects();
  }
}

interface IDateOptions {
  today: IDateOption;
  yesterday: IDateOption;
  lastWeek: IDateOption;
  lastMonth: IDateOption;
  last90Days: IDateOption;
  custom: IDateOption;
}

interface IDateOption {
  value: StatisticsDateOption;
  text: string;
}
