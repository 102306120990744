import { throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { LocationModel } from 'app/models/location.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const CONFIG_TYPES = ["Jontek", "", "Enovation", "Sky Response"]; // Empty string is used for default value

function configTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Check if the type of the value matches the expected type
    if (!CONFIG_TYPES.includes(control.value)) {
      return { 'invalidConfigType': true }; // Return an error object if the validation fails
    }

    return null; // Validation passed
  };
}

@Component({
  selector: 'location-iotcomms-settings',
  templateUrl: './location-iotcomms-settings.component.html',
  styleUrls: ['./location-iotcomms-settings.component.css'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, TranslateModule, MatSlideToggleModule]
})
export class LocationIotcommsSettingsComponent implements OnChanges, OnInit, OnDestroy {

  // Init global variables and services
  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder
  ) {
  };

  @Input('location') location: LocationModel;

  iotCommsForm: UntypedFormGroup;
  iotCommsConfiguration: any;
  iotCommsConfigurationLoaded: boolean;
  updateSuccess: boolean;
  updateError: boolean;
  arcRegEx: RegExp = new RegExp("sip:(\\+[0-9]*)@(.*)");
  provisionDone: boolean = false;
  provisionSuccess: number = 0;
  provisionFailed: number = 0;

  formSubmitSubject = new Subject();

  get scaipEnabled(): boolean {
    return this.iotCommsForm.value.scaipEnabled;
  }

  createIotCommsForm(): void {
    this.iotCommsForm = this.fb.group({
      scaipEnabled: [this.iotCommsConfiguration?.scaipEnabled ?? false],
      configType: [this.iotCommsConfiguration?.configType ?? "", configTypeValidator()],
      destination: [this.iotCommsConfiguration?.arcDestination ?? ""],
      domain: [this.iotCommsConfiguration?.domain ?? "everon.prod-eu-north-1.iotcomms.io"],
      schemeId: [this.iotCommsConfiguration?.schemeId ?? "", Validators.required],
      audio: [this.iotCommsConfiguration?.audio ?? "", Validators.required],
      retryTime: [this.iotCommsConfiguration?.retryTime ?? "120", Validators.required],
      eventReceiver: [this.iotCommsConfiguration?.eventReceiver ?? ""],
      callbackSIP: [this.iotCommsConfiguration?.callbackSIP ?? ""],
      arcName: [this.iotCommsConfiguration?.arcName ?? ""],
      arcNumber: [this.iotCommsConfiguration?.arcNumber ?? ""]
    });
    this.updateScaipDependentValidators();
  }

  updateScaipDependentValidators(): void {
    const controls = {
      destination: this.iotCommsForm.get("destination"),
      domain: this.iotCommsForm.get("domain"),
      eventReceiver: this.iotCommsForm.get("eventReceiver"),
      callbackSIP: this.iotCommsForm.get("callbackSIP")
    };

    const requiredValidators = [Validators.required];

    if (this.scaipEnabled) {
      this.clearValidators([controls.destination, controls.domain]);
      this.addValidators([controls.eventReceiver, controls.callbackSIP], requiredValidators);
    } else {
      this.clearValidators([controls.eventReceiver, controls.callbackSIP]);
      this.addValidators([controls.destination, controls.domain], requiredValidators);
    }

    Object.values(controls).forEach(control => control.updateValueAndValidity());
  }

  submitIotCommsForm(): void {
    this.updateError, this.updateSuccess = false;

    let data = {
      scaipEnabled: this.scaipEnabled,
      configType: this.iotCommsForm.value.configType.trim(),
      location: this.location.id,
      arcDestination: !this.scaipEnabled ? (this.iotCommsForm.value.destination?.trim() || "") : "", // Field needs to be empty, if SCAIP is enabled
      domain: !this.scaipEnabled ? (this.iotCommsForm.value.domain?.trim() || "") : "",
      schemeId: this.iotCommsForm.value.schemeId,
      audio: this.iotCommsForm.value.audio,
      retryTime: this.iotCommsForm.value.retryTime,
      eventReceiver: this.scaipEnabled ? (this.iotCommsForm.value.eventReceiver?.trim() || "") : "",
      callbackSIP: this.scaipEnabled ? (this.iotCommsForm.value.callbackSIP?.trim() || "") : "",
      arcName: this.iotCommsForm.value.arcName?.trim() || "",
      arcNumber: this.iotCommsForm.value.arcNumber?.trim() || ""
    }

    this.api.setIotCommsConfigurationForLocation(data).subscribe(res => {
      this.updateSuccess = true;
    }, err => {
      this.updateError = true;
    })
  }

  syncToIotCOmms(): void {
    this.provisionDone = false;
  
    this.api.syncLocationToIotComms(this.location.id).subscribe(res => {
      this.provisionDone = true;
      this.provisionSuccess = res.success;
      this.provisionFailed = res.fail
    }, err => {
      this.provisionDone = true;
    })
  }

  ngOnInit() {
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitIotCommsForm();
      });
  }

  ngOnChanges() {
    this.updateError = false;
    this.updateSuccess = false;
    this.api.getIotCommsConfigurationForLocation(this.location.id).subscribe(res => {
      this.iotCommsConfiguration = res;
      this.iotCommsConfigurationLoaded = true;
      this.createIotCommsForm();
    });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }

  private clearValidators(controls: any[]): void {
    controls.forEach(control => control.clearValidators());
  }

  private addValidators(controls: any[], validators: any): void {
    controls.forEach(control => control.addValidators(validators));
  }
}

