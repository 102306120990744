import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { ApiService } from 'app/services/api.service';

export interface INightowlForm {
  nightowlId: string;
  lyraId: string;
  locationId: string;
  deviceName: string;
}

@Component({
  selector: 'app-nightowl-form',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, MatTooltipModule, MatFormFieldModule, MatSelectModule, MatOptionModule],
  templateUrl: './nightowl-form.component.html',
  styleUrls: ['./nightowl-form.component.css']
})
export class NightowlFormComponent {
  @Output() closeEvent = new EventEmitter<void>();
  @Output() savedEvent = new EventEmitter<string>();

  @Input() locations: { id: string, name: string }[];

  form: FormGroup;
  errorMsg: string;
  showSpinner: boolean = false;

  get nightowlId() {
    return this.form.get('nightowlId')?.value;
  }

  set nightowlId(value: any) {
    this.form.get('nightowlId')?.setValue(value);
  }

  constructor(private api: ApiService) {
    this.form = new FormGroup({
      nightowlId: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(/^NUA.{5}$/)]), // Pattern for "NUAxxxxx"
      lyraId: new FormControl({ value: '', disabled: true }, Validators.required),
      locationId: new FormControl('', Validators.required),
      deviceName: new FormControl(''),
    });
  }

  verifyId(): void {
    const nightowlId = this.nightowlId;
    this.showSpinner = true;
    this.errorMsg = "";

    this.api.getNightowlAvailability(nightowlId).subscribe({
      next: res => {
        // Reassign the value in case the user has changed it while the request was in progress
        this.nightowlId = nightowlId;
        this.showSpinner = false;

        if (!res) {
          this.errorMsg = 'ERROR';
          console.error('API error: no response received');
        }
        else if (!res.available) {
          this.errorMsg = 'DEVICE_VALIDITY_INUSE';
        }
        else if (!res.lyraId) {
          this.errorMsg = 'ERROR';
          console.error('API error: Lyra ID was not provided');
        }
        else {
          this.form.controls.lyraId.setValue(res.lyraId);
          this.form.controls.nightowlId.disable();
        }
      },
      error: err => {
        console.error(err);
        this.errorMsg = "ERROR";
        this.nightowlId = nightowlId;
        this.showSpinner = false;
      }
    })
  }

  submit(): INightowlForm {
    if (this.form.invalid) return null;

    const request: INightowlForm = {
      nightowlId: this.form.controls.nightowlId.value,
      lyraId: this.form.controls.lyraId.value,
      locationId: this.form.controls.locationId.value,
      deviceName: this.form.controls.deviceName.value
    }

    this.showSpinner = true;

    this.api.saveNightowl(request).subscribe({
      next: () => {
        this.showSpinner = false;
        this.savedEvent.emit(request.lyraId);
      },
      error: err => {
        console.error(err);
        this.errorMsg = "ERROR";
        this.showSpinner = false;
      }
    });

    return request;
  }

  clearForm(): void {
    this.form.reset();
    this.form.controls.locationId.setValue('');
    this.form.controls.nightowlId.enable();
  }
}
