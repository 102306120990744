<!-- View device information-->
<div class="card-borders" *ngIf="device">

    <div class="d-flex justify-content-between align-items-center mb-3 bg-color-lightgrey">
        <div>
            <h6 class="mb-0 dev-info-heading">{{ "DEVICE_INFORMATION" | translate }}</h6>
        </div>
        <div *ngIf="userRoleLevel >= 50">
            <button
                class="btn btn-outline-primary white-bg mr-2"
                matTooltip="{{ 'TOOLTIP_MODIFY_DEVICE' | translate }}"
                (click)="onEditMode()">
                <span class="fal fa-fw fa-edit"></span>
                {{ "MODIFY" | translate }}
            </button>
        </div>
    </div>

    <div class="d-flex flex-row mx-2 mb-2">
        <!-- left column -->
        <div class="d-flex flex-column half-width-column">

            <!-- NAME -->
            <div>
                <div>
                    <strong>{{ "NAME" | translate }}:</strong>
                </div>
                <div>
                    {{ device.name }}
                </div>
            </div>

            <!-- RESIDENT -->
            <div *ngIf="residentsLength > 0">
                <div>
                    <strong>{{ "RESIDENT" | translate }}:</strong>
                </div>
                <div id="deviceInfoResidentDisplay">
                    {{ device.residentDisplayName }}
                </div>
            </div>

            <!-- RESIDENT_NAME -->
            <div *ngIf="device.lockInformation?.firstName && device.lockInformation?.familyName">
                <div>
                    <strong>{{ "RESIDENT_NAME" | translate }}:</strong>
                </div>
                <div>
                    {{ device.lockInformation.firstName }} {{ device.lockInformation.familyName }}
                </div>
            </div>

            <!-- LOCATION -->
            <div>
                <div>
                    <strong>{{ "LOCATION" | translate }}:</strong>
                </div>
                <div>
                    {{ device.locationName }} ({{ device.locationId }})
                </div>
            </div>

            <!-- ADDRESS -->
            <div *ngIf="device.address || device.postCode || device.postOffice">
                <div>
                    <strong>{{ "ADDRESS" | translate }}:</strong>
                </div>
                <div>
                    {{ device.address }}, {{ device.postCode }} {{ device.postOffice }}
                </div>
            </div>

            <!-- FLOOR_NUMBER -->
            <div *ngIf="device.lockInformation?.floorNumber">
                <div>
                    <strong>{{ "FLOOR_NUMBER" | translate }}:</strong>
                </div>
                <div>
                    {{ device.lockInformation.floorNumber }}
                </div>
            </div>

            <!-- ROOM_NUMBER -->
            <div *ngIf="device.lockInformation?.roomNumber">
                <div>
                    <strong>{{ "ROOM_NUMBER" | translate }}:</strong>
                </div>
                <div>
                    {{ device.lockInformation.roomNumber }}
                </div>
            </div>

            <!-- LOCKED_DOOR_TYPE -->
            <div *ngIf="device.lockInformation?.lockedDoorTypeID">
                <div>
                    <strong>{{ "LOCKED_DOOR_TYPE" | translate }}:</strong>
                </div>
                <div>
                    {{ device.lockInformation.lockedDoorTypeID }}
                </div>
            </div>

            <!-- DEVICE_ADDITIONAL_ALARM_INFO -->
            <div *ngIf="device.additionalAlarmInfo">
                <div>
                    <strong>{{ "DEVICE_ADDITIONAL_ALARM_INFO" | translate }}:</strong>
                </div>
                <div>
                    {{ device.additionalAlarmInfo }}
                </div>
            </div>

            <!-- DEVICE_ADDITIONAL_INFO -->
            <div *ngIf="device.additionalInfo && userRoleLevel >= 50">
                <div>
                    <strong>{{ "DEVICE_ADDITIONAL_INFO" | translate }}:</strong>
                </div>
                <div>
                    {{ device.additionalInfo }}
                </div>
            </div>

            <!-- DEVICE_LAST_CONTACT -->
            <div *ngIf="device.lastConnection &&
            device.lastConnection !== '0001-01-01T00:00:00Z' &&
            device.deviceType !== '06-1'">
                <div>
                    <strong>{{ "DEVICE_LAST_CONTACT" | translate }}:</strong>
                </div>
                <div>
                    <span
                        *ngIf="device.onlineStatus === 'Offline' && device.lastConnection !== '0001-01-01T00:00:00Z'"
                        class="fal fa-exclamation-triangle text-danger"></span>

                    {{ device.lastConnection | moment : "l LTS" }}
                </div>
            </div>

            <!-- ACTIVE_CONNECTION -->
            <div *ngIf="device.id?.substr(0, 2) == '31'">
                <div>
                    <strong>{{ "ACTIVE_CONNECTION" | translate }}:</strong>
                </div>
                <div [ngSwitch]="device.connectionType"
                    matTooltip="{{service.getActiveConnectionInfo(device.signalLevels, device.connectionType)}}"
                    [matTooltipPosition]="'before'">
                    <!-- service.getColorClass(device.signalLevels) -->
                    <span *ngSwitchCase="0" [ngClass]="'text-dark'"
                        class="fa-solid fa-signal"></span>
                    <span *ngSwitchCase="1" [ngClass]="'text-dark'"
                        class="fa-solid fa-network-wired"></span>
                    <span *ngSwitchCase="2" [ngClass]="'text-dark'"
                        class="fa-solid fa-wifi"></span>
                    &nbsp;{{service.getActiveConnectionInfo(device.signalLevels,
                    device.connectionType)}}
                </div>
            </div>

            <!-- BATTERY_STATUS -->
            <div *ngIf="device.batteryStateList">

                <div>
                    <strong>{{ "BATTERY_STATUS" | translate }}:</strong>
                </div>

                <div>
                    <div *ngFor="let batt of device.batteryStateList"
                        [ngClass]="{ 'd-inline': device.batteryStateList.length === 1 }">
                        <span
                            class="fa-solid fa-fw fa-lg"
                            [ngClass]="service.getBatteryClass(batt.batteryLevel)">
                        </span>
                        <span>
                            {{device.batteryStateList.length < 2 ? null :
                                service.getBatteryType(batt.batteryType) }}
                                </span>
                    </div>
                </div>
            </div>

        </div>

        <!-- right column -->
        <div class="d-flex flex-column half-width-column">

            <!-- SERIAL_NUMBER -->
            <div>
                <div><strong>{{ "SERIAL_NUMBER" | translate }}:</strong></div>
                <div>
                    {{ device.id }}
                    <span
                        *ngIf="
                            device.id?.substr(0, 2) === '31' &&
                            device.replaceDevice &&
                            device.replaceDevice !== '00000000' &&
                            device.replaceDevice !== '0'">
                        ({{ "REPLACED_WITH" | translate }} {{ device.replaceDevice }})
                    </span>
                </div>
            </div>

            <!-- BASESTATION_ID -->
            <div *ngIf="device.basestationId && device.deviceType === '08-1'">
                <div>
                    <strong>{{ "BASESTATION_ID" | translate }}:</strong>
                </div>
                <div>
                    {{ device.basestationId }}
                </div>
            </div>

            <!-- LOCK_SERIAL_NUMBER -->
            <div *ngIf="device.lockInformation?.lockSerialNumber">
                <div>
                    <strong>{{ "LOCK_SERIAL_NUMBER" | translate }}:</strong>
                </div>
                <div>
                    {{ device.lockInformation.lockSerialNumber }}
                </div>
            </div>

            <!-- EverWatch ID -->
            <div *ngIf="device.everWatchId">
                <div>
                    <strong>{{ "EverWatch " + ("SERIAL_NUMBER" | translate | lowercase) }}:</strong>
                </div>
                <div>
                    {{ device.everWatchId }}
                </div>
            </div>

            <!-- GSM_NUMBER -->
            <div *ngIf="device.gsmNumber && device.id">
                <div>
                    <strong>{{ "GSM_NUMBER" | translate }}:</strong>
                </div>
                <div>
                    {{ device.gsmNumber }}
                </div>
            </div>

            <!-- DEVICE_SOFTWARE_VERSION -->
            <div *ngIf="device.softwareVersion">
                <div>
                    <strong>{{ "DEVICE_SOFTWARE_VERSION" | translate }}:</strong>
                </div>
                <div>
                    {{ device.softwareVersion }}
                </div>
            </div>

            <!-- DEV_SUB_CLASS -->
            <div *ngIf="iotCommsEnabled && device.devSubClass !== 0 && device.devSubType">
                <div>
                    <strong>{{ "DEV_SUB_CLASS" | translate }}:</strong>
                </div>
                <div>
                    {{ device.devSubType | translate }}
                </div>
            </div>

            <!-- EXTERNAL_IDENTIFIER -->
            <div *ngIf="iotCommsEnabled && device.externalSystemId">
                <div>
                    <strong>{{ "EXTERNAL_IDENTIFIER" | translate }}:</strong>
                </div>
                <div>
                    {{ device.externalSystemId }}
                </div>
            </div>

            <!-- Nightowl serial number -->
            <div *ngIf="device.deviceType === '34-4' && device.externalSystemId">
                <div>
                    <strong>{{ "Nightowl " + ("SERIAL_NUMBER" | translate | lowercase) }}:</strong>
                </div>
                <div>
                    {{ device.externalSystemId }}
                </div>
            </div>

            <!-- ADDED_TO_SYSTEM -->
            <div *ngIf="device.addedToSystem !== '0001-01-01T00:00:00Z'">
                <div>
                    <strong>{{ "ADDED_TO_SYSTEM" | translate }}:</strong>
                </div>
                <div>
                    {{ device.addedToSystem | moment : "l LTS" }}
                </div>
            </div>

        </div>
    </div>

    <div class="m-3" *ngIf="device.deviceType === '06-1'">
        <a
            href="https://myvega.everon.net"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-outline-primary gray-bg">
            <span class="fal fa-fw fa-arrow-right"></span> Vega Manager
        </a>
    </div>

    <div class="m-3" data-testid="everwatch-manager-link" *ngIf="device?.deviceType === '07-1'">
        <a
            href="https://everwatch.everon.net/"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-primary">
            EverWatch Manager
        </a>
    </div>
</div>
