import { Component, OnInit, OnDestroy } from "@angular/core";
import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";
import { StatisticsService } from "app/services/statistics.service";
import { AlarmService } from "app/services/alarm.service";
import { UiLang } from "app/services/helper.service";
import { MatDialog } from "@angular/material/dialog";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { CareRecipientCardComponent } from "../../care-recipient-card/care-recipient-card.component";
import { Subscription, combineLatest, fromEvent, interval } from "rxjs";
import { filter, switchMap, takeUntil, map } from "rxjs/operators";
import { FadeOutAnimation } from "app/animations";
import {
  CareRecipientCardFormDialogComponent,
  IDialogData,
} from "../../care-recipient-card-form-dialog/care-recipient-card-form-dialog.component";
import {
  CareRecipientStatistics,
  CareRecipientCard,
} from "app/models/statistics.model";
import {
  DragDropModule,
  CdkDropList,
  CdkDropListGroup,
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
} from "@angular/cdk/drag-drop";

@Component({
  selector: "care-recipients-tab",
  templateUrl: "./care-recipients-tab.component.html",
  styleUrls: ["./care-recipients-tab.component.css"],
  animations: [FadeOutAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TranslateModule,
    CareRecipientCardComponent,
    DragDropModule,
    CdkDropList,
    CdkDropListGroup,
    CdkDrag,
  ],
})
export class CareRecipientsTabComponent implements OnInit, OnDestroy {
  constructor(
    public statistics: StatisticsService,
    private alarmService: AlarmService,
    private dialog: MatDialog
  ) {}

  cardRows: CareRecipientStatistics[][] = [];
  translations: IAlarmReasonTranslations;
  longestAvgWaitTime: number = 0;
  longestAvgCareTime: number = 0;
  cardsCountEvenToThree: boolean = true;
  isCardDragged: boolean = false;

  subscriptions: Subscription[] = [];

  onCardDrop(
    event: CdkDragDrop<{
      rowIndex: number;
      item: CareRecipientStatistics[];
    }>
  ): void {
    // Swap card places within the same list
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.cardRows[event.container.data.rowIndex],
        event.previousIndex,
        event.currentIndex
      );
    }
    // Swap card between lists
    else {
      const currentRow = event.container.data.rowIndex;
      const currentColumn = event.currentIndex;
      const previousRow = event.previousContainer.data.rowIndex;
      const previousColumn = event.previousIndex;

      const draggedCard = this.cardRows[previousRow][previousColumn];
      const cardToSwap = this.cardRows[currentRow][currentColumn];

      // Swap cards
      if (cardToSwap) {
        this.cardRows[currentRow][currentColumn] = draggedCard;
        this.cardRows[previousRow][previousColumn] = cardToSwap;
      }
    }
  }

  openNewCareRecipientCardDialog(): void {
    const user: any = JSON.parse(localStorage.getItem("user"));

    const dialogData: IDialogData = {
      title: "NEW_CARD",
      card: new CareRecipientCard(user),
    };

    const dialogRef = this.dialog.open(CareRecipientCardFormDialogComponent, {
      width: "45%",
      data: dialogData,
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((statusMsg: string) => {
      if (statusMsg) {
        this.statistics.careRecipientTabResultMsg = statusMsg;
        this.statistics.fetchCareRecipientCards$.next();
      }
    });
  }

  findLongestAvgTimes(matrix: CareRecipientStatistics[][]): {
    longestAvgWaitTime: number;
    longestAvgCareTime: number;
  } {
    const results = { longestAvgWaitTime: 0, longestAvgCareTime: 0 };

    for (let row = 0; row < matrix.length; row++) {
      for (let column = 0; column < matrix[row].length; column++) {
        if (
          matrix[row][column].data.avgWaitTimeSeconds >
          results.longestAvgWaitTime
        ) {
          results.longestAvgWaitTime =
            matrix[row][column].data.avgWaitTimeSeconds;
        }
        if (
          matrix[row][column].data.avgCareTimeSeconds >
          results.longestAvgCareTime
        ) {
          results.longestAvgCareTime =
            matrix[row][column].data.avgCareTimeSeconds;
        }
      }
    }
    return results;
  }

  isEvenToThree(matrix: CareRecipientStatistics[][]): boolean {
    const count = matrix.reduce((total, row) => total + row.length, 0);
    return count % 3 === 0;
  }

  removeCardFromList(cardId: string): void {
    const matrix: CareRecipientStatistics[][] = [];
    let array: CareRecipientStatistics[] = [];
    const maxRowSize = 3;

    // Remove deleted card from the list and re-order the card rows
    for (let row = 0; row < this.cardRows.length; row++) {
      for (let column = 0; column < this.cardRows[row].length; column++) {
        const careRecipientStats = this.cardRows[row][column];

        // Push and empty the array if maximum row size is achieved
        if (array.length === maxRowSize) {
          matrix.push(array);
          array = [];
        }

        // Exclude removed card from the list
        if (careRecipientStats.card.id !== cardId) {
          array.push(careRecipientStats);
        }
      }
    }

    // Push the parsed row if not empty
    if (array.length > 0) {
      matrix.push(array);
    }

    // Replace the displayed rows and check position of the "Add new" card place holder
    this.cardRows = matrix;
    this.cardsCountEvenToThree = this.isEvenToThree(this.cardRows);
  }

  subscribeToCardRemoval(): Subscription {
    // Remove card from the list when it's successfully deleted in API
    const subscription = this.statistics.removedCareRecipientCardId$.subscribe(
      (id) => {
        if (id) {
          this.removeCardFromList(id);
        }
      }
    );
    return subscription;
  }

  subscribeTofetchViewData(langId: UiLang): Subscription {
    const careRecipientCards$ = this.statistics.careRecipientCards$;
    const translation$ = this.alarmService.getAlarmReasonTranslations(langId);

    const subscription = combineLatest([
      careRecipientCards$,
      translation$,
    ]).subscribe(
      ([cardRows, translations]) => {
        if (!this.translations && translations) {
          this.translations = translations;
        }
        if (cardRows) {
          this.cardRows = this.statistics.careRecipientCards = cardRows;

          // Check whether there is full rows only or is there one partial row included to display "Add new" block correctly
          this.cardsCountEvenToThree = this.isEvenToThree(this.cardRows);

          // Find the longest average times of cards
          const results = this.findLongestAvgTimes(this.cardRows);

          this.longestAvgWaitTime = results.longestAvgWaitTime;
          this.longestAvgCareTime = results.longestAvgCareTime;

          this.statistics.careRecipientCardsLoaded = true;

          // Fade out displayed messages if any is set
          if (this.statistics.careRecipientTabResultMsg) {
            this.statistics.removeMessageTimer();
          }
        }
        this.statistics.careRecipientCardsLoaded = true;
      },
      (status) => {
        console.log(status.error);
        this.statistics.careRecipientCardsLoaded = true;
      }
    );

    this.statistics.fetchCareRecipientCards$.next();
    return subscription;
  }

  subscribeToDragAutoScrollEvent(): Subscription {
    // Create observables for card dragging events
    const mouseDown$ = fromEvent(document, "mousedown");
    const mouseUp$ = fromEvent(document, "mouseup");
    const mouseMove$ = fromEvent(document, "mousemove");

    const mouseHold$ = mouseDown$.pipe(
      switchMap(() => {
        return interval(10).pipe(takeUntil(mouseUp$));
      })
    );

    // Combine and subscribe mouseHold$ and mouseMove$ to get the cursor y-axis position
    return combineLatest([mouseHold$, mouseMove$])
      .pipe(
        filter(() => this.isCardDragged), // Emit only if card is dragged
        map(([_, event]) => event)
      )
      .subscribe((event: MouseEvent) => {
        this.dragAutoScroll(event.clientY);
      });
  }

  dragAutoScroll(cursorPositionY: number): void {
    const viewportHeight = window.innerHeight;
    const scrollThreshold = 100; // pixels
    const scrolledDistance = 10; // pixels

    // Scroll up if cursor is above top threshold (100 pixels below the top)
    if (cursorPositionY < scrollThreshold) {
      window.scrollBy(0, -scrolledDistance);
    }
    // Scroll down if cursor is below bottom threshold
    else if (cursorPositionY > viewportHeight - scrollThreshold) {
      window.scrollBy(0, scrolledDistance);
    }
  }

  ngOnInit(): void {
    const language = localStorage.getItem("language") as UiLang;

    this.subscriptions.push(this.subscribeTofetchViewData(language));
    this.subscriptions.push(this.subscribeToCardRemoval());
    this.subscriptions.push(this.subscribeToDragAutoScrollEvent());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
