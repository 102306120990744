<div class="card" style="width: 100%">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">{{ ("ADD_NEW" | translate) + ' Nightowl' }}</h6>
        <span class="fa-light fa-xmark float-right cardCloseBtn" (click)="closeEvent.emit()"></span>
    </div>

    <div class="card-body-white">
        <div class="alert alert-danger" *ngIf="errorMsg">
            {{ errorMsg | translate }}
        </div>

        <div class="alert alert-danger" *ngIf="form.get('nightowlId')?.invalid && form.get('nightowlId')?.touched">
            {{ "SERIAL_NUMBER_FORMAT_ERROR" | translate }} <strong>NUAxxxxx</strong>
        </div>

        <form class="pt-1 pl-4" [formGroup]="form">
            <label>
                <strong class="pr-1">{{ "SERIAL_NUMBER" | translate }}</strong>
                <span [matTooltip]="'ENTER_NIGHTOWL_ID' | translate" class="fa fa-fw fa-circle-info"></span>
            </label>

            <input
              class="form-control"
              type="text"
              name="serialNumber"
              formControlName="nightowlId"
              [placeholder]='("SERIAL_NUMBER" | translate) + " (NUAxxxxx)"'
            >

            <ng-container *ngIf="form.get('lyraId').value">
                <label class="mt-3"><strong>Lyra ID</strong></label>
                <input class="form-control" type="text" formControlName="lyraId" name="lyraId">

                <div class="mt-3">
                    <div class="from-group">
                        <label><strong>{{'LOCATION' | translate}}</strong></label>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="locationId">
                            <mat-option [value]="''">{{ 'LOCATION' | translate }}</mat-option>
                            <mat-option *ngFor="let location of locations" [value]="location.id">
                                {{ location.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <label><strong>{{ "NAME" | translate }}</strong></label>
                <input class="form-control" type="text" [placeholder]='"NAME" | translate' formControlName="deviceName" name="name">
            </ng-container>

            <div class="form-group mt-4">
                <button
                  type="button"
                  class="btn btn-outline-primary gray-bg mr-1"
                  data-testid="verify-button"
                  *ngIf="!form.get('lyraId').value; else submitBtns"
                  [disabled]="form.get('nightowlId').invalid"
                  (click)="verifyId()"
                >
                    <i [ngClass]="showSpinner ? 'fas fa-circle-notch fa-spin mr-1' : 'fa-light fa-check'"></i>
                    {{ showSpinner ? "VERIFYING" : "VERIFY" | translate }}
                </button>

                <ng-template #submitBtns>
                    <button
                      type="button"
                      data-testid="submit-button"
                      class="btn btn-primary"
                      [disabled]="form.invalid || !form.controls.nightowlId.value || !form.controls.lyraId || showSpinner"
                      (click)="submit()"
                    >
                        <i [ngClass]="showSpinner ? 'fad fa-spinner-third fa-spin mr-2' : 'fa-light fa-floppy-disk mr-1'"></i>
                        {{ showSpinner ? "SAVING" : "SAVE" | translate }}
                    </button>

                    <button type="button" data-testid="cancel-button" class="btn btn-outline-primary gray-bg ml-2" [disabled]="showSpinner" (click)="clearForm()">
                        <i class="fa-light fa-xmark"></i>
                        {{ "CLEAR_FORM" | translate }}
                    </button>
                </ng-template>
            </div>
        </form>
    </div>
</div>
