import { DomSanitizer } from "@angular/platform-browser";
import {
  AllResidentSorter,
  AllResidents,
} from "../../../models/resident.model";
import { HighlightSearch } from "../../../pipes/pipes";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "app/services/api.service";
import { AnimationCollapse, AnimationCollapseLeave } from "../../../animations";
import { Observable, Subject, Subscription, fromEvent } from "rxjs";
import { i18nPaginator } from "../new-staff/i18nPaginator";
import { TranslateResidentDevicePipe } from "./all-residents-pipes";
import {
  MatExpansionPanel,
  MatExpansionModule,
} from "@angular/material/expansion";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { SortOrder } from "app/services/helper.service";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";

@Component({
  selector: "all-residents",
  templateUrl: "./all-residents.component.html",
  styleUrls: ["./all-residents.component.css"],
  animations: [AnimationCollapse, AnimationCollapseLeave],
  standalone: true,
  providers: [{ provide: MatPaginatorIntl, useClass: i18nPaginator }],
  imports: [
    NgIf,
    MatPaginatorModule,
    FormsModule,
    NgFor,
    MatExpansionModule,
    TranslateModule,
    HighlightSearch,
    TranslateResidentDevicePipe,
  ],
})
export class AllResidentsComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private sanitized: DomSanitizer,
    private cookies: CookieService
  ) {
    this.sortOutput = this.sortAllResidents$.pipe(
      switchMap(() => {
        return this.getSortedAllResidents();
      })
    );
    this.subscription = this.sortOutput.subscribe((res: any) => {
      this.parseGetAllResidentsResponse(res);
    });
  }

  private _residentFilter: string;

  @ViewChild("residentSearchInput", { static: true })
  residentSearchInput: ElementRef;
  @ViewChild(MatExpansionPanel, { static: true })
  matExpansionPanel: MatExpansionPanel;

  allResidents: any;
  deviceTranslations: any[];
  customerSelectionData: any;

  sortAllResidents$ = new Subject<any>();
  sortOutput: Observable<any>;
  sortKey: AllResidentSorter;
  sortBy: SortOrder = SortOrder.Default;
  selectedSortHeader: SortKeyName;
  subscription: Subscription;

  pageInited: boolean = false;
  allResidentsLoaded: boolean = false;

  allResPageData: any = {
    resultCount: 0,
    pageSize: 0,
  };

  filteredResidents = [];

  dataSource = null;

  get residentFilter(): string {
    return this._residentFilter;
  }

  set residentFilter(value: string) {
    this._residentFilter = value;
    this.filteredResidents = this.transform(this.allResidents, value);
    if (value == "" && !this.allResidentsLoaded) {
      this.allResidentsLoaded = true;
      this.search(this.resPage);
    } else if (value != "") {
      this.allResidentsLoaded = false;
    }
  }

  async loadDeviceTranslations() {
    await new Promise<void>((r) => {
      this.api
        .getDeviceTranslationsForAuthenticatedUser(localStorage.getItem("language"))
        .subscribe((res) => {
          localStorage.setItem("devType", JSON.stringify(res));
          this.deviceTranslations = res;
          r();
        });
    });
  }

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      return (
        (typeof it.agrigatedDevices !== "undefined"
          ? this.searchDevices(it.agrigatedDevices, searchText, it.index).length
          : false) ||
        (typeof it.residentName !== "undefined"
          ? it.residentName.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.address !== "undefined"
          ? it.address.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.company !== "undefined"
          ? it.company.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.phoneHome !== "undefined"
          ? it.phoneHome.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.phoneCell !== "undefined"
          ? it.phoneCell.toLocaleLowerCase().includes(searchText)
          : false)
      );
    });
  }

  searchDevices(items: any[], searchText: string, index: number): any[] {
    if (!items) {
      return [];
    }

    searchText = searchText.toLocaleLowerCase();

    let result = [];
    if (items.length) {
      result = items.filter((it) => {
        return (
          (typeof it.devType !== "undefined"
            ? it.devType.toLocaleLowerCase().includes(searchText)
            : false) ||
          (typeof it.devName !== "undefined"
            ? it.devName.toLocaleLowerCase().includes(searchText)
            : false) ||
          (typeof it.devStatus !== "undefined"
            ? it.devStatus.toLocaleLowerCase().includes(searchText)
            : false) ||
          (typeof it.deviceId !== "undefined"
            ? it.deviceId.toLocaleLowerCase().includes(searchText)
            : false)
        );
      });
    }
    if (!searchText.length) {
      this.allResidents[index].panelOpen = false;
    } else {
      this.allResidents[index].panelOpen = result.length > 0;
    }

    return result;
  }

  onPanelOpen(index: number): void {
    const resident = this.filteredResidents[index];
    this.getResidentDevices(resident);
  }

  resPage = 0;
  pageCount = 0;

  search(page: number) {
    let search = this.residentSearchInput.nativeElement.value;
    this.resPage = page;
    this.api
      .getCompanyGroupAllResidents(
        this.resPage,
        search,
        this.allResPageData.pageSize,
        this.sortKey,
        this.sortBy
      )
      .subscribe(
        (res) => {
          this.parseGetAllResidentsResponse(res);
        },
        (err) => {
          console.log(err);
        }
      );

    return false;
  }

  sortTable(sortKeyName: SortKeyName): void {
    const selectedSortKey = AllResidentSorter[sortKeyName];

    if (selectedSortKey !== this.sortKey) {
      this.sortBy = SortOrder.Default;
    }
    this.selectedSortHeader = sortKeyName;
    this.sortKey = selectedSortKey;

    switch (this.sortBy) {
      case SortOrder.Default:
        this.sortBy = SortOrder.Asc;
        break;
      case SortOrder.Asc:
        this.sortBy = SortOrder.Desc;
        break;
      case SortOrder.Desc:
        this.sortBy = SortOrder.Default;
    }

    this.sortAllResidents$.next();
  }

  parseGetAllResidentsResponse(res: any): void {
    this.allResidents = res.result;
    this.allResPageData.resultCount = res.resultCount;
    let i = 0;
    for (let _ of this.allResidents) {
      this.allResidents[i].index = i;
      this.allResidents[i++].panelOpen = false;
    }

    this.pageCount = Math.ceil(res.resultCount / res.pageSize);
    this.filteredResidents = this.allResidents;
    this.residentFilter = this.residentSearchInput.nativeElement.value;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  selectPage(page: number) {
    this.search(page);
  }

  getResidentDevices(resident: any): void {
    const residentId = resident.residentId;

    this.api.getResidentDevices(residentId).subscribe((devices: any[]) => {
      resident.agrigatedDevices = devices;
    });
  }

  getSortedAllResidents(): Observable<any> {
    const key = this.sortKey;
    const order = this.sortBy;
    const currentPage = this.resPage;
    const search = "";

    return this.api.getCompanyGroupAllResidents(
      currentPage,
      search,
      this.allResPageData.pageSize,
      key,
      order
    );
  }

  // Not needed
  /*
  initAllResidents(data: any): void {
    if (data.result?.length) {
      this.allResPageData.resultCount = data.resultCount;
      this.allResPageData.pageSize = data.pageSize;

      var checker = setInterval(() => {
        if (this.deviceTranslations?.length) {
          this.initAllResidentsWithDeviceTranslations(data.result);
          clearInterval(checker);
        }
      }, 1);
    }
  }

  // Not needed
  initAllResidentsWithDeviceTranslations(data: any): void {
    this.allResidents = data;
    let r_id = 0;
    let c = 0;

    for (let i = 0; i < this.allResidents.length; i++) {
      let d_id = 0;
      if ("agrigatedDevices" in this.allResidents[i]) {
        for (let device of this.allResidents[i].agrigatedDevices) {
          for (let devTrans of this.deviceTranslations) {
            if (
              device.devType == devTrans.deviceType &&
              this.allResidents[r_id].agrigatedDevices
            ) {
              this.allResidents[r_id].agrigatedDevices[d_id].devType =
                devTrans.deviceTypeName + " (" + devTrans.deviceTypeCode + ")";
            }
            c++;
          }
          d_id++;
        }
      }
      r_id++;
    }

    let i = 0;
    for (let _ of this.allResidents) {
      this.allResidents[i].index = i;
      this.allResidents[i++].panelOpen = false;
    }

    this.pageCount = Math.ceil(
      this.allResPageData.resultCount / this.allResPageData.pageSize
    );

    this.filteredResidents = this.allResidents;
  }
  */

  handleGetAllResidentsResponse(data: any): void {
    if (data.result?.length) {
      this.allResidents = data.result;
      this.allResPageData.resultCount = data.resultCount;
      this.allResPageData.pageSize = data.pageSize;

      this.allResidents.forEach((resident: any, index: number) => {
        resident.index = index;
        resident.panelOpen = false;
      });

      this.pageCount = Math.ceil(
        this.allResPageData.resultCount / this.allResPageData.pageSize
      );

      this.filteredResidents = this.allResidents;
    }
  }

  loadAllResidents(): void {
    this.api.getCompanyGroupAllResidents(0, "").subscribe(
      (res) => {
        //this.initAllResidents(res);
        this.handleGetAllResidentsResponse(res);
        this.allResidentsLoaded = true;
        this.pageInited = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit(): void {
    this.loadAllResidents();
    this.loadDeviceTranslations();

    fromEvent(this.residentSearchInput.nativeElement, "keyupenter")
      .pipe(
        // get value
        map((event: any) => event.target.value),
        // Time in milliseconds between key events
        debounceTime(50),
        // If previous query is different from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe(
        (query: string) => (this.residentFilter = query.toLowerCase())
      );
  }

  currentPage = 0;

  handlePageEvent(pageEvent: PageEvent) {
    let pageSize = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    let search = this.residentSearchInput.nativeElement.value;
    this.api
      .getCompanyGroupAllResidents(this.currentPage, search, pageSize)
      .subscribe(
        (res) => {
          //this.initAllResidents(res);
          this.handleGetAllResidentsResponse(res);
          this.allResidentsLoaded = true;
          this.pageInited = true;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

type SortKeyName = "Name" | "Address" | "Company" | "SSnumber" | "DeviceCount" | "PhoneHome" | "PhoneCell";
