import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { CrSyncService } from '../../../services/crsync.service';
import { CookieService } from 'ngx-cookie-service';
import { PasswordChangeDialogComponent } from '../password-change-dialog/password-change-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { LocalStorageService } from 'app/services/local-storage.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-logout-options',
  templateUrl: './logout-options.component.html',
  styleUrls: ['./logout-options.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    TranslateModule,
    MatSelectModule]
})
export class LogoutOptionsComponent {
  @Input() user: any;
  @Input() language: any;
  @Input() loggedCustomer: any;
  @Input() loginButtonStyle: any;
  landingPage: any;
  initials: string;
  enterpriseName: string;
  shortenedEnterpriseName: string;
  customerId: string;
  @Input() logoutBtnForMobileSidenav: boolean;
  @Input() showTitles: any;
  @Input() onMobileScreen: any;

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    private crSync: CrSyncService,
    private cookies: CookieService,
    private router: Router,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) { }

  languageOptions: uiLanguageOption[] = [
    { lang: 'fi', imgSrc: 'assets/img/flags/fi.png', alt: 'Finnish' },
    { lang: 'sv', imgSrc: 'assets/img/flags/sv.png', alt: 'Swedish' },
    { lang: 'en', imgSrc: 'assets/img/flags/uk.png', alt: 'English' },
    { lang: 'ee', imgSrc: 'assets/img/flags/ee.png', alt: 'Estonian' },
  ];

  selectedLanguage: uiLanguageOption;

  ngOnChanges() {
    this.getInitials();
    this.language = localStorage.getItem('language');

    if ((this.router.url.startsWith('/landing-page') || this.router.url.startsWith("/login/federation")) && !this.landingPage) {
      this.landingPage = JSON.parse(localStorage.getItem('landing-page'));
    } else {
      this.landingPage = null;
    }

    if (localStorage.getItem('landing-page')) {
      this.landingPage = JSON.parse(localStorage.getItem('landing-page'));
    }
  }

  showNav(): boolean {
    let url = this.router.url;
    if (!url.includes('login') && !url.startsWith('/ap/')) {
      return true;
    }
    return false;
  }

  ngDoCheck() {
    this.getEneterpriseName();
    this.getCustomerId();
  }

  getCustomerInfo() {
    return JSON.parse(localStorage.getItem("customerInfo"));
  }

  getCustomerId() {
    this.customerId = this.getCustomerInfo()?.customerId;
  }

  getInitials() {
    let user = JSON.parse(localStorage.getItem("user"));
    user?.firstName[0] && user?.familyName[0] ?
      this.initials = user?.firstName[0] + user?.familyName[0]
      : this.initials = null;
  }

  getEneterpriseName() {
    this.enterpriseName = this.getCustomerInfo()?.name;

    if (this.enterpriseName?.length > 0) {
      this.shortenedEnterpriseName = this.enterpriseName;
      if (this.enterpriseName.length > 15) {
        let words = this.enterpriseName.split(' ');
        let shortenedName = '';
        let ind = 0;
        while (ind < words.length) {
          if (shortenedName.length > 15) {
            ind = words.length;
            this.shortenedEnterpriseName = shortenedName + ' ...';
            continue;
          }
          shortenedName += words[ind] + ' '
          ind++;
        }
      }
    }
  }

  logout(): void {
    if (this.loggedAsCustomer()) {
      this.switchCustomer();
      return;
    }
    this.crSync.disconnect();

    // Check for possible landinge page user
    let lpUser = JSON.parse(localStorage.getItem("landing-page"))?.user;

    // Different logout logic for authenticated users
    if ((this.user && this.user.authenticated) || lpUser) {
      this.api.logoutAuthenticatedUser(lpUser ? true : false).subscribe(res => {
        this.api.logout().subscribe((response) => {
          if (res) {
            window.location.href = res;
          } else {
            window.location.href = '/login'
          }

        });
      }, err => {
        this.api.logout().subscribe((response) => {
          window.location.href = '/login'
        });
      });
    } else {
      // Log the logout event for non-authenticated users before logging out
      this.api.logLogoutEvent()
        .pipe(switchMap(() => this.api.logout()))
        .subscribe(
          () => window.location.href = '/login',
          () => this.api.logout().subscribe(() => window.location.href = '/login') // Proceed to logout even if logging fails
        );
    }
  }

  backToLandingpage(): void {

    if (this.loggedAsCustomer()) {
      this.switchCustomer();
      return;
    }
    this.crSync.disconnect();

    var token = localStorage.getItem("landingpage-token");
    this.cookies.set("session-token", token, undefined, "/", undefined, false, "Lax");

    localStorage.removeItem("landingpage-token");

    localStorage.removeItem("fromLandingPage");
    localStorage.setItem("landing-page", localStorage.getItem("landingpage-temp"))
    localStorage.removeItem("landingpage-temp");

    localStorage.removeItem("act");
    localStorage.removeItem("customerInfo");
    localStorage.removeItem("devType");
    localStorage.removeItem("features");
    localStorage.removeItem("user");

    this.router.navigate(["landing-page"]);
  }


  switchCustomer(): void {
    this.cookies.set('session-token', this.cookies.get('session-token-admin'), undefined, "/", undefined, false, "Lax")
    this.cookies.delete('session-token-admin', "/", undefined, false, "Lax");
    this.cookies.delete('onon-session-token', "/", undefined, false, "Lax");
    this.cookies.delete('videoserver-session-token', "/", undefined, false, "Lax");
    let user = localStorage.getItem('user-admin');
    let lang = localStorage.getItem('language');

    localStorage.clear();
    localStorage.setItem('user', user);
    localStorage.setItem('language', lang);
    window.location.href = 'dashboard/admin';
  }

  loggedAsCustomer(): boolean {
    if (this.cookies.get('session-token-admin')) {
      this.loggedCustomer = localStorage.getItem('loggedCustomer');
      return true
    }
    return false;
  }

  isAdminLoggedInSSO(): boolean {
    if (this.cookies.get('session-token-admin') && this.router.url.includes('/landing-page')) {
      return true;
    }
    return false;
  }

  fromLandingPage(): boolean {
    return localStorage.getItem("fromLandingPage") == 'true';
  }

  showPasswordChangeDialog(): void {
    const dialogRef = this.dialog.open(PasswordChangeDialogComponent, {
      disableClose: true
    });
  }

  ngOnInit() {
    if (this.languageOptions && this.languageOptions.length > 0) {
      this.selectedLanguage = this.languageOptions.find(
        (option) => option.lang === localStorage.getItem('language')
      );
    }
  }

  onSelectionChange(event: MatSelectChange) {
    this.localStorageService.setLanguage(event.value.lang);
    this.translate.use(event.value.lang)
  }
}

interface uiLanguageOption {
  lang: string,
  imgSrc: string,
  alt: string
}
