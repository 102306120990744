<mat-toolbar>
    <button *ngIf="onMobileScreen && user" (click)="onSideNavOpen()" mat-button
        class="openSideNavButton"
        aria-label="Open navigation bar">
        <mat-icon>menu</mat-icon>
    </button>

    <button *ngIf="!onMobileScreen && (language === 'fi' || language === 'sv') && user"
        class="helpBtn button-rounded"
        [matMenuTriggerFor]="userManual">
        <i class="fa-solid fa-question deepBlue"></i>
    </button>

    <mat-menu #userManual="matMenu">
        <div class="menuWithPaddings">
            <a target="_blank" [href]="'https://everonstorage.blob.core.windows.net/manuals/portal_'+language+'.pdf'">
                {{'PORTAL_MANUAL' |translate}}
            </a>
            <a *ngIf="language === 'fi' || language === 'sv'|| language === 'en'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_'+language+'.pdf'">
                {{'EMA_MANUAL' |translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_ios_'+language+'.pdf'">
                {{'EMA_IOS_MANUAL' |translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/onon_'+language+'.pdf'">
                {{'ONON_MANUAL' | translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_network_'+language+'.pdf'">
                {{'EMA_V2_NETWORKING_SPEC_MANUAL' | translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/pre-fetch_'+language+'.pdf'">
                {{'DIGITAL_KEY_LOADING_MANUAL' | translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_danalock_'+language+'.pdf'">
                {{'DANALOCK_V3_WITH_EMA_V2_MANUAL' | translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_masterlock_'+language+'.pdf'">
                {{'MASTERLOCK_MEDICAL_CABINET_WITH_EMA_V2_MANUAL' | translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_salto_locker_'+language+'.pdf'">
                {{'SALTO_LOCKER_MEDICAL_CABINET_WITH_EMA_V2_MANUAL' | translate}}
            </a>
            <a *ngIf="language === 'sv'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_salto_neo_'+language+'.pdf'">
                {{'SALTO_NEO_MEDICAL_CABINET_WITH_EMA_V2_MANUAL' | translate}}
            </a>
        </div>
    </mat-menu>
</mat-toolbar>
